import {
  useContext, ref, computed, onMounted, readonly, nextTick,
  reactive,
} from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useGeolocationStore } from '~/stores/geolocation';
import { useStore } from '~/composables';
import type { Coords, UseGeolocationErrors, UseGeolocationInterface } from './useGeolocation';
import { DEFAULT_CITIES } from './constants';

const fetchState = reactive({
  locateFromIpLoading: false,
  locateLoading: false,
});

export function useGeolocation(): UseGeolocationInterface {
  const { $dadata } = useContext();
  const { changeByLocation } = useStore();

  const userCoords = ref<Coords | null>(null);
  const error = ref<UseGeolocationErrors>({ getPositionFromNavigator: null, locateUserCity: null });
  const loading = ref(false);

  const geolocationStore = useGeolocationStore();
  const userLocation = computed(() => geolocationStore.location);
  const toConfirmLocation = computed(() => geolocationStore.toConfirmLocation);

  const setUserConfirmation = (e: boolean) => {
    geolocationStore.$patch((state) => {
      state.toConfirmLocation = e;

      if (state.location && !e) {
        changeByLocation(state.location);
      }
    });
  };

  const setDefaultCityToStore = () => {
    geolocationStore.$patch((state) => {
      changeByLocation(state.location)
        .then(() => {
          state.location = DEFAULT_CITIES.MOSCOW;

          return state.location;
        }).catch((err) => {
          console.error(err);
        });
    });
  };

  const proccessLocatedCoords = (data: GeolocationPosition) => {
    const { latitude, longitude } = data.coords as Coords;
    userCoords.value = { latitude, longitude };
  };

  const processLocateError = (geolocationError: GeolocationPositionError) => {
    const { code } = geolocationError;

    switch (code) {
      case GeolocationPositionError.TIMEOUT:
        error.value.getPositionFromNavigator = new Error('GEOLOCATION_TIMEOUT_ERROR');
        break;
      case GeolocationPositionError.PERMISSION_DENIED:
        error.value.getPositionFromNavigator = new Error('GEOLOCATION_PERMISION_DENIED_ERROR');
        break;
      case GeolocationPositionError.POSITION_UNAVAILABLE:
        error.value.getPositionFromNavigator = new Error('GEOLOCATION_POSITION_UNAVAILABLE_ERROR');
        break;
      default:
        error.value.getPositionFromNavigator = new Error('GEOLOCATION_ERROR');
        break;
    }

    throw error.value.getPositionFromNavigator as Error;
  };

  const getPositionFromNavigator = async () => new Promise<void>((resolve, reject) => {
    if (navigator.geolocation) {
      error.value.getPositionFromNavigator = null;
      loading.value = true;

      try {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            loading.value = false;
            resolve(proccessLocatedCoords(position));
          },
          (geolocationError) => {
            loading.value = false;
            reject(processLocateError(geolocationError));
          },
          { enableHighAccuracy: false, timeout: 7200 },
        );
      } catch (geolocationError) {
        Logger.debug('[ERROR] useGeolocation/getPositionFromNavigator', geolocationError);
        loading.value = false;
      }
    } else {
      error.value.getPositionFromNavigator = null;
    }
  });

  const locateUserCity = async () => {
    fetchState.locateLoading = true;
    await getPositionFromNavigator();
    error.value.locateUserCity = null;

    try {
      const data = await $dadata.geolocateCity([userCoords.value.latitude, userCoords.value.longitude]);
      geolocationStore.$patch((state) => {
        const location = data.suggestions.length > 0 ? (data.suggestions[0].data || null) : null;

        if (location !== null) {
          changeByLocation(location)
            .then(() => {
              state.location = location;

              return location;
            }).catch((err) => {
              console.error(err);
            });
        }
      });

      nextTick(() => {
        setUserConfirmation(true);
      });
    } catch (err) {
      Logger.debug('[ERROR] useGeolocation/locateUserCity', err);
      error.value.locateUserCity = err;
    } finally {
      loading.value = false;
      fetchState.locateLoading = false;
    }
  };

  const locateUserCityFromIp = async () => {
    error.value.locateUserCity = null;
    fetchState.locateFromIpLoading = true;
    loading.value = true;

    try {
      const data = await $dadata.geolocateCityFromIp({ ip: geolocationStore.ip });

      geolocationStore.$patch((state) => {
        const location = data.location?.data || null;

        if (location !== null) {
          changeByLocation(location)
            .then(() => {
              state.location = location;

              return location;
            }).catch((err) => {
              console.error(err);
            });
        } else {
          setDefaultCityToStore();
        }
      });
    } catch (err) {
      Logger.debug('[ERROR] useGeolocation/locateUserCityFromIp', err);
      error.value.locateUserCity = err;

      setDefaultCityToStore();
    } finally {
      fetchState.locateFromIpLoading = false;
      loading.value = false;
    }
  };

  const setChoosedCity = (data) => {
    geolocationStore.$patch((state) => {
      const location = data;

      if (location) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        changeByLocation(location)
          .then(() => {
            state.location = location;

            return location;
          }).catch((err) => {
            console.error(err);
          });
      }
    });
  };

  onMounted(async () => {
    if (geolocationStore.location || fetchState.locateFromIpLoading) {
      try {
        await changeByLocation(geolocationStore.location);
      } catch (err) {
        console.error(err);
      }

      return;
    }

    await locateUserCityFromIp();
    await locateUserCity();
  });

  return {
    locateUserCity,
    location: userLocation,

    toConfirmLocation,
    confirmLocation: () => setUserConfirmation(false),

    setChoosedCity,

    error: readonly(error),
    loading: readonly(computed(() => fetchState.locateFromIpLoading || fetchState.locateLoading || loading.value)),
  };
}

export * from './constants';
export * from './useGeolocation';
export default useGeolocation;
