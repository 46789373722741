





















































































































import { useCurrentBreakpoint } from '~/composables/useCurrentBreakpoint';
import { debounce } from 'lodash-es';
import {
  defineComponent,
  onMounted,
  ref,
  useContext,
  watch,
  onUnmounted,
  computed,
} from '@nuxtjs/composition-api';
import {
  SfSearchBar, SfSidebar, SfButton,
} from '@storefront-ui/vue';
import { UserGeolocationData } from '~/stores/geolocation';
import { DEFAULT_CITIES } from '~/composables/useGeolocation';
import { DadataCitySuggestionResponseType } from '~/repositories/dadata/types';
import { storeToRefs } from 'pinia';
import SvgImage from '~/components/General/SvgImage.vue';
import { useLocateCityDrawerState } from './locate-city-drawer.store';

export default defineComponent({
  name: 'DadataLocateCiteDrawerSearch',

  components: {
    SfButton,
    SfSearchBar,
    SfSidebar,
    SvgImage,
  },

  props: {
    minQueryLength: {
      type: Number,
      default: 2,
    },
    delay: {
      type: Number,
      default: 300,
    },
    visibleCount: {
      type: Number,
      default: 6,
    },
  },

  emits: ['close', 'chooseCity'],

  setup(props, { emit }) {
    const store = useLocateCityDrawerState();
    const { searchDrawerVisible } = storeToRefs(store);
    const { $dadata } = useContext();
    const proposedCities = Object.values(DEFAULT_CITIES);
    const suggestions = ref<DadataCitySuggestionResponseType['data'][]>([]);
    const suggestionsVisible = ref(false);

    const headerRef = ref<HTMLElement | null>(null);
    const headerHeight = ref(136);

    const { breakpoint } = useCurrentBreakpoint();
    const isForDesktop = computed(() => ['desktop'].includes(breakpoint.value));

    const maxContentHeight = ref('300px');
    const updateMaxContentHeight = () => {
      if (headerRef.value) {
        headerHeight.value = headerRef.value.clientHeight || 136;
        maxContentHeight.value = `calc(${window.innerHeight}px - ${isForDesktop.value ? '140px' : '150px'} - ${headerHeight.value}px)`;
      }

      let value = '100vh';

      if (window.innerWidth && window.innerWidth <= 1024) {
        value = `${window.innerHeight}px`;
      }

      document.documentElement.style.setProperty('--real100vh', value);
    };
    watch(
      () => headerRef.value,
      () => {
        updateMaxContentHeight();
      },
      { flush: 'post' },
    );

    onMounted(() => {
      window.addEventListener('resize', updateMaxContentHeight);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', updateMaxContentHeight);
    });

    const searchValue = ref('');

    const getSuggestionsCities = async () => {
      try {
        const data = await $dadata.suggestCity({
          query: searchValue.value,
          from_bound: {
            value: 'city',
          },
          to_bound: {
            value: 'settlement',
          },
          restrict_value: true,
        });

        suggestions.value = data.suggestions
          .map((suggestion) => ({ ...suggestion.data, value: suggestion.value }));
      } catch (error) {
        console.log(error);
        suggestions.value = [];
      }
    };

    const getSuggestionsCitiesDebounced = debounce(getSuggestionsCities, props.delay);

    const onSearchInput = async (v: string) => {
      searchValue.value = v;

      const validLength = v.length >= props.minQueryLength;
      suggestionsVisible.value = validLength;

      if (validLength) {
        await getSuggestionsCitiesDebounced();
      }
    };

    const close = () => {
      emit('close');
      searchValue.value = '';
      suggestions.value = [];
    };

    const handleClose = () => {
      close();
    };

    const chooseProposedCity = (city: UserGeolocationData) => {
      emit('chooseCity', city);
      close();
    };

    const onSuggestionClick = (idx: number) => {
      emit('chooseCity', suggestions.value[idx]);
      close();
    };

    const handleKeydownEnter = (searchTerm: string) => {
      getSuggestionsCitiesDebounced.cancel();
      onSearchInput(searchTerm);
    };

    const clearSearchValue = () => {
      searchValue.value = '';
      suggestions.value = [];
    };

    return {
      headerRef,
      headerHeight,
      searchDrawerVisible,
      handleClose,

      clearSearchValue,
      handleKeydownEnter,

      proposedCities,
      chooseProposedCity,

      searchValue,
      onSearchInput,
      suggestions,
      suggestionsVisible,

      maxContentHeight,
      onSuggestionClick,
    };
  },
});
