import type { Cart, Maybe, Scalars } from '~/modules/GraphQL/types';

export enum CartErrorType {
  UNDEFINED = 'UNDEFINED',
  MIN_ORDER_AMOUNT = 'MIN_ORDER_AMOUNT',
  ITEM_QTY = 'ITEM_QTY',
}

export type CartCustom = Cart & {
  errors?: Maybe<{
    code: CartErrorType;
    message: Scalars['String'];
  }[]>;
  use_reward_points?: Scalars['Boolean'];
  reward_points_applied?: Scalars['Int'];
  max_allowed_point_to_apply?: Scalars['Int'];
};
