import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import {
  CustomerCancelOrderQuery,
  CustomerOrder,
  CustomerOrdersQuery,
  useUiNotification,
} from '~/composables';
import type {
  UseUserOrderErrors,
  UseUserOrderInterface,
  UseUserOrderSearchParams,
} from './useUserOrder';

import customerOrdersGql from './customerOrders.gql';
import cancelOrderGql from './cancelOrder.gql';

export const ORDER_STATUS_CODE = {
  PAYMENT_REVIEW: 'payment_review',
};

/**
 * Allows fetching customer orders.
 *
 * See the {@link UseUserOrderInterface} for a list of methods and values available in this composable.
 */
export function useUserOrder(): UseUserOrderInterface {
  const { app } = useContext();
  const loading = ref(false);
  const isCancelModalVisible = ref(false);
  const error = ref<UseUserOrderErrors>({
    search: null,
    cancel: null,
  });

  const { send: sendNotification } = useUiNotification();
  const { app: { i18n } } = useContext();

  const search = async ({
    filter,
    currentPage,
    pageSize,
  }: UseUserOrderSearchParams) => {
    let results = null;

    try {
      loading.value = true;

      Logger.debug('[Magento] search user orders', { filter, currentPage, pageSize });

      const { data } = await app.$vsf.$magento.api.customQuery({
        query: customerOrdersGql,
        queryVariables: {
          filter,
          currentPage,
          pageSize,
        },
      }) as { data: CustomerOrdersQuery };

      Logger.debug('[Result]:', { data });

      results = data?.customer?.orders ?? null;
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useRelatedProducts/search', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  const showCancelOrderModal = () => {
    isCancelModalVisible.value = true;
  };

  const cancel = async (order: CustomerOrder) => {
    let result = null;

    try {
      loading.value = true;

      Logger.debug('[Magento] cancel user order', order.number);

      const { data } = await app.$vsf.$magento.api.customQuery({
        query: cancelOrderGql,
        queryVariables: {
          orderNumber: order.number,
        },
      }) as { data: CustomerCancelOrderQuery };

      Logger.debug('[Result]:', { data });

      result = data?.order ?? null;
      error.value.cancel = null;

      sendNotification({
        id: Symbol('order_removed'),
        message: i18n.t('customer.orderHistory.details.successCancel') as string,
        type: 'danger',
        icon: null,
        persist: false,
        title: i18n.t('Order canceled') as string,
      });
    } catch (err) {
      error.value.cancel = err;
      Logger.error('useUserOrder/cancel', err);
    } finally {
      loading.value = false;
    }

    return result;
  };

  return {
    search,
    cancel,
    showCancelOrderModal,
    loading: readonly(loading),
    isCancelModalVisible: readonly(isCancelModalVisible),
    error: readonly(error),
  };
}

export default useUserOrder;
export * from './useUserOrder';
