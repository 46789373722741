import { CustomerLoyaltyCardNumber } from '~/modules/customer/composables/useUserLoyaltyCard/types';

export function mockExtendedLoyaltyCardNumber(): { getDiscountCard: CustomerLoyaltyCardNumber } {
  return {
    getDiscountCard: {
      number: '0',
      number_formatted: '0',
    },
  };
}
