import { UseContextReturn } from '~/types/core';
import type { PlaceOrderOutput } from '~/modules/GraphQL/types';
import { ComposableFunctionArgs } from '~/composables';
import { PlaceOrderMutation } from '@vue-storefront/magento-types';

export const placeOrderCommand = {
  execute: async (
    context: UseContextReturn,
    cartId: string,
    params?: ComposableFunctionArgs<{}>,
  ): Promise<PlaceOrderOutput | { error: string } | null> => {
    const { data, errors } = await context.app.$vsf.$magento.api.customMutation({
      mutation: `
        mutation placeOrder($input: PlaceOrderInput) {
          placeOrder(input: $input) {
            order {
              order_number
              gateway_url
            }
          }
        }
      `,
      mutationVariables: {
        input: {
          cart_id: cartId,
        },
      },
      customHeaders: params?.customHeaders,
    });

    if (errors && errors[0]) {
      return {
        error: errors[0].message,
      };
    }

    const { placeOrder } = data as PlaceOrderMutation;

    return placeOrder ?? null;
  },
};
