/* eslint-disable  */

export default {
  "About us": "О нас",
  "Account": "Аккаунт",
  "Add new address": "Добавить новый адрес ",
  "Add to compare": "Add to compare",
  "Add to Wishlist": "Добавить к сравнению",
  "Additional Information": "Дополнительная информация",
  "All Orders": "Все заказы",
  "Allow order notifications": "Разрешить уведомления о заказах",
  "Apply": "Применить",
  "Applied Coupon": "Примененный купон ",
  "Attention!": "Внимание!",
  "Back to home": "Вернуться",
  "Back to homepage": "Вернуться на главную",
  "Become a partner": "Стать партнером",
  "Billing": "Оплата",
  "Billing address": "Адрес для выставления счета",
  "Brand": "Бренд",
  "Cancel": "Отмена",
  "Cart": "Корзина",
  "Categories": "Категории",
  "Change": "Изменить",
  "Change password your account": "Если вы хотите изменить пароль для доступа к своей учетной записи, введите следующую информацию",
  "Clear all": "Очистить все",
  "Clear": "Очистить",
  "Reset all filters": "Сбросить все фильтры",
  "Color": "Цвет",
  "Enter another email": "Ввести другой e-mail",
  "Commercial information": "и соглашаетесь получать персонализированную коммерческую информацию от бренда по электронной почте",
  "Contact details updated": "Держите свои адреса и контактные данные в актуальном состоянии.",
  "Contact us": "Связаться с нами",
  "Continue to billing": "К оплате ",
  "Continue to payment": "Продолжить оплату",
  "Continue to shipping": "К доставке",
  "Company": "Компания",
  "Cookies Policy": "Политика использования файлов cookie",
  "Create an account": "Зарегестрироваться",
  "Customer Reviews": "Отзывы клиентов",
  "Customer service": "Обслуживание клиентов",
  "Date": "Дата",
  "Default Billing Address": "Основной адрес владельца счета ",
  "Default Shipping Address": "Основной адрес для пересылки",
  "Delete": "Удалить",
  "Delivery": "Доставка",
  "Departments": "Отделы",
  "Description": "Описание",
  "Details and status orders": "Проверяйте детали и статус ваших заказов в интернет-магазине. Вы также можете отменить заказ или запросить возврат.",
  "Discount": "Скидка",
  "Dimensions": "Размеры",
  "Done": "Готово",
  "Download": "Скачать",
  "Download all": "Скачать все",
  "Gift certificates": "Подарочные сертификаты",
  "Edit": "Изменить",
  "Email address": "Адрес электронной почты",
  "Empty": "Похоже, вы еще не добавили ни одного товара в корзину. Начните делать покупки, чтобы заполнить его.",
  "Empty bag": "Пустая корзина",
  "Enjoy your free account": "Наслаждайтесь этими преимуществами с помощью бесплатной учетной записи!",
  "Enter promo code": "Введите промокод",
  "FAQ": "Частые вопросы",
  "Feedback": "Ваше мнение очень важно для нас. Дайте нам знать, что мы могли бы улучшить.",
  "Feel free to edit": "Не стесняйтесь редактировать любые свои данные ниже, чтобы ваша учетная запись всегда была актуальной.",
  "Filters": "Фильтры",
  "Find out more": "Узнать больше",
  "First Name": "Имя",
  "Forgot Password": "Если вы не помните свой пароль, вы можете его сбросить.",
  "Forgot Password Modal Email": "Электронная почта, которую вы используете для входа",
  "forgotPasswordConfirmation": "Спасибо! Если на адрес электронной почты {0} зарегистрирована учетная запись, вы найдете сообщение со ссылкой для сброса пароля в своем почтовом ящике.",
  "Forgotten password?": "Забытый пароль ?",
  "Go back": "Вернуться",
  "Go back shopping": "Вернуться к покупкам ",
  "Go back to shop": "Вернуться в магазин ",
  "Go to checkout": "Перейти к оформлению заказа",
  "Go to cart": "Перейти в корзину ",
  "Guarantee": "Гарантия",
  "Help": "Помощь",
  "Help & FAQs": "Помощь и FAQs",
  "Helpful information": "Полезная информация",
  "hide": "скрыть",
  "Hide": "Скрыть",
  "Home": "Главная",
  "I agree to": "я согласен с",
  "I confirm subscription": "Я подтверждаю подписку",
  "I want to create an account": "Я хочу создать учетную запись",
  "Info after order": "Вы можете войти в свою учетную запись, используя адрес электронной почты и пароль, определенные ранее. В своей учетной записи вы можете редактировать данные своего профиля, проверять историю транзакций, редактировать подписку на рассылку новостей.",
  "Instruction1": "Позаботься обо мне",
  "Instruction2": "Просто здесь для инструкций по уходу?",
  "Instruction3": "Да, мы так и думали",
  "It was not possible to request a new password, please check the entered email address.": "Не удалось запросить новый пароль, проверьте введенный адрес электронной почты.",
  "Item": "Товар",
  "Items": "Товары",
  "Kidswear": "Детская одежда",
  "Last Name": "Фамилия",
  "What new things will we choose today?": "Какие обновки подберем сегодня?",
  "Unfortunately, we don’t have this, try changing the request": "К сожалению, такого у нас нет, попробуй изменить запрос",
  "Let’s start now – we’ll help you": "Начнем прямо сейчас – мы вам поможем.",
  "Log into your account": "Войдите в свой аккаунт",
  "Login": "Авторизоваться",
  "Loyalty card": "Карта лояльности",
  "login in to your account": "войдите в свою учетную запись",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Похоже, вы еще не добавили ни одного товара в корзину. Начните делать покупки, чтобы заполнить его.",  "Looks like you haven’t added any items to the Wishlist.": "Looks like you haven’t added any items to the Wishlist.",
  "Make an order": "Оформить заказ",
  "Manage addresses": "Управляйте всеми адресами, которые вы хотите (место работы, домашний адрес...). Таким образом, вам не придется вводить адрес вручную при каждом заказе.",
  "Manage billing addresses": "Управляйте всеми нужными вам платежными адресами (рабочее место, домашний адрес...). Таким образом, вам не придется вводить платежный адрес вручную при каждом заказе.",
  "Manage shipping addresses": "Управляйте всеми адресами доставки, которые вы хотите (место работы, домашний адрес...). Таким образом, вам не придется вручную вводить адрес доставки при каждом заказе.",
  "Match it with": "Сопоставьте его с",
  "Men fashion": "Мужская мода",
  "Catalog": "Каталог",
  "My billing and shipping address are the same": "Мой платежный адрес и адрес доставки совпадают",
  "My Cart": "Моя корзина",
  "No account": "У вас еще нет учетной записи?",
  "or": "или",
  "or fill the details below": "или заполните данные ниже",
  "Order ID": "Номер заказа",
  "Order information": "Запросить информацию",
  "Order No.": "№ заказа.",
  "Order summary": "Итог заказа",
  "Other products you might like": "Другие товары, которые могут вам понравиться",
  "Out of stock": "Нет в наличии",
  "Stock": "Акции",
  "Password": "Пароль",
  "Password Changed": "Пароль успешно изменен. Теперь вы можете вернуться на главную страницу и войти в систему.",
  "Pay for order": "Оплатить заказ",
  "Payment": "Оплата",
  "Payment & Delivery": "Оплата и доставка",
  "Payment Method": "Способ оплаты",
  "Payment Methods": "Способы оплаты",
  "Personal details": "Личная информация",
  "Please type your current password to change your email address.": "Пожалуйста, введите свой текущий пароль, чтобы изменить свой адрес электронной почты.",
  "Price": "Цена",
  "Privacy": "Конфиденциальность",
  "Privacy Policy": "Политика конфиденциальности",
  "Product": "Товар",
  "Product care": "Уход за изделиями",
  "Product suggestions": "Предложения по товару",
  "Products": "Товары",
  "Products found": "Товары найдены",
  "Procurement": "Закупки",
  "Purchase terms": "Условия покупки",
  "Quality in the details": "Качество в деталях",
  "Quantity": "Количество",
  "Read all reviews": "Прочитать все отзывы",
  "Read and understand": "Я прочитал и понял",
  "Read reviews": "Читать отзывы",
  "Register": "Регстрация",
  "Register today": "Зарегистрируйтесь сегодня",
  "Remove": "Удалить",
  "Remove Address": "Удалить адресс",
  "Remove from Wishlist": "Удалить из списка желаний",
  "Repeat Password": "Повторить пароль",
  "Reset Password": "Сбросить пароль",
  "Review my order": "Проверить мой заказ",
  "Same as shipping address": "То же, что и адрес доставки",
  "Save changes": "Сохранить изменения",
  "Save for later": "Сохранить позже",
  "Save Password": "Сохраните пароль",
  "Search": "Поиск",
  "Search for items": "Поиск товаров",
  "Search results": "Результаты поиска",
  "Sections that interest you": "Разделы, которые вас интересуют",
  "See all results": "Посмотреть все результаты ",
  "See more": "Узнать больше",
  "See all": "Смотреть все",
  "Collapse": "Свернуть",
  "Select payment method": "Выберите метод оплаты",
  "Select shipping method": "Выберите способ доставки",
  "Send my feedback": "Отправить мой отзыв",
  "Set up newsletter": "Настройте рассылку, и мы будем присылать вам информацию о новых продуктах и ​​тенденциях из выбранных вами разделов каждую неделю.",
  "Share your look": "Поделиться мнением",
  "Stylist": "Стилист",
  "Shipping": "Перевозки",
  "Shipping address": "Адрес доставки",
  "Shipping details": "Отгрузочные реквизиты",
  "Shipping method": "Способ доставки",
  "Show":"Показать",
  "Items on page": "Товаров",
  "show more": "показать больше",
  "Show on page": "Показать на странице:",
  "Sign in": "Войти",
  "Size guide": "Гид по размерам",
  "Sign Up for Newsletter": "Подписаться на рассылку",
  "Sort by": "Сортировать по",
  "Sort: Default": "По умолчанию",
  "Sort: Name A-Z": "Названия А-Я",
  "Sort: Name Z-A": "Названия Я-А",
  "Sort: Price from high to low": "Цена от высокой к низкой",
  "Sort: Price from low to high": "Цена от низкой к высокой",
  "Start shopping": "Начать покупки",
  "Status": "Статус",
  "Subscribe": "Подписаться",
  "Subscribe to newsletter": "Подписаться на новостную рассылку",
  "subscribeToNewsletterModalContent": "После подписки на рассылку новостей вы будете получать специальные предложения и сообщения от VSF по электронной почте. Мы никогда не будем продавать или распространять вашу электронную почту третьим лицам. Пожалуйста, посмотрите наш {0}.",
  "Subtotal": "Промежуточный итог ",
  "Subtotal price": "Итоговая цена ",
  "Successful placed order": "Вы успешно разместили заказ. Вы можете проверить статус вашего заказа, используя нашу функцию статуса доставки. Вы получите электронное письмо с подтверждением заказа с подробной информацией о вашем заказе и ссылкой для отслеживания его выполнения.",
  "Terms and conditions": "Условия и положения",
  "Thank you": "Спасибо",
  "Thank You Inbox": "Если сообщение не приходит на ваш почтовый ящик, попробуйте другой адрес электронной почты, который вы, возможно, использовали для регистрации.",
  "Total": "Итог",
  "Order Total": "Итого",
  "Total items": "Всего товаров",
  "Total price": "Обшая цена",
  "Update password": "Обновить пароль",
  "Update personal data": "Обновить личные данные",
  "Use your personal data": "В Brand name мы придаем большое значение вопросам конфиденциальности и стремимся защищать личные данные наших пользователей. Узнайте больше о том, как мы заботимся и используем ваши персональные данные, в разделе",
  "User Account": "Учетная запись пользователя",
  "View": "Посмотреть",
  "View details": "Посмотреть детали",
  "Vue Storefront Next": "Vue Storefront Next",
  "Vacancies": "Вакансии",
  "Who we are": "О нас",
  "Wishlist": "Список желаний",
  "Women fashion": "Женская мода",
  "You added {product} to your shopping cart.": "Вы добавили  {product} в корзину.",
  "You are not authorized, please log in": "Вы не авторизованы, пожалуйста, войдите",
  "You can unsubscribe at any time": "Вы можете отписаться в любое время",
  "You currently have no orders": "На данный момент у вас нет заказов",
  "You haven\’t searched for items yet": "Вы еще не искали товары.",
  "Your bag is empty": "Ваша корзина пуста",
  "Your current email address is": "Ваш текущий адрес электронной почты",
  "Your email": "Ваш адрес электронной почты",
  "The email field must be a valid email":"Поле электронной почты должно быть действительным адресом электронной почты.",
  'You have submitted no reviews': 'Вы не предоставили ни одного отзыва',
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.":"Вход в учетную запись был неправильным, или ваша учетная запись временно отключена. Пожалуйста, подождите и повторите попытку позже.",
  "A customer with the same email address already exists in an associated website.": "Клиент с таким же адресом электронной почты уже существует на связанном веб-сайте.",
  "Invalid email": "Неверный адрес электронной почты",
  "SUMMER COLLECTION {year}": "ЛЕТНЯЯ КОЛЛЕКЦИЯ {year}",
  "Make yourself known!": "Заяви о себе!",
  "New2": "New",
  "Best": "Best",
  "unique hits!": "уникальные хиты!",
  "Learn more": "Узнать больше",
  "For new products": "За новинками",
  "Dresses": "Платья",
  "Cocktail & Party": "Коктейльная вечеринка",
  "Linen Dresses": "Льняные платья",
  "T-Shirts": "Футболки",
  "The office life": "Офисная жизнь",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Найдите потрясающие женские коктейльные и вечерние платья. Выделитесь в коктейльных платьях из кружева и металлик от всех ваших любимых брендов.",
  "Shop now": "Shop now",
  "The Office Life": "Офисная жизнь",
  "Summer Sandals": "Летние сандалии",
  "Eco Sandals": "Эко-сандалии",
  "Subscribe to Newsletters": " Подпишитесь на рассылку",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Следи за трендами, новинками и акциями",
  "Find out before anyone else about trends, promotions and discounts": "Узнавай раньше всех о трендах, акциях и скидках",
  "Fashion to take away": "скачай приложение",
  "Download our application to your mobile": "Получи скидку 10% на первый заказ",
  "Share Your Look": "Поделитесь своим мнением",
  "My Account": "Мой аккаунт",
  "My profile": "Мой профиль",
  "Personal Details": "Личная информация",
  "Addresses details": "Детали адресов",
  "My newsletter": "Моя рассылка",
  "Log out": "Выйти",
  "My reviews": "Мои отзывы",
  "Order history": "История заказов",
  "Order details": "Информация для заказа",
  "My wishlist": "Мой список желаний",
  "Password change": "Смена пароля",
  "Personal data": "Личные данные",
  "Your e-mail": "Ваш адрес электронной почты",
  "Current Password": "Текущий пароль",
  "You are not authorized, please log in.": "Вы не авторизованы, пожалуйста, войдите.",
  "Go To Product": "Перейти в каталог",
  "Change to list view": "Перейти к просмотру списка",
  "Change to grid view": "Переключиться на вид сетки",
  "Returns":"Возврат",
  "My orders": "Мои заказы",
  "Add the address": "Добавить адресс",
  "Set as default shipping": "Установить как доставку по умолчанию",
  "Set as default billing": "Установить в качестве платежного средства по умолчанию",
  "House/Apartment number": "Номер дома/квартиры",
  "Street Name": "Название улицы",
  "City": "Город",
  "State/Province": "Штат/Провинция",
  "Zip-code": "Почтовый индекс",
  "Country": "СТрана",
  "Phone number": "Номер телефона",
  "Please select a country first":"Пожалуйста, сначала выберите страну",
  "This field is required": "Это поле обязательно",
  "The field should have at least 2 characters": "Поле должно содержать не менее 2 символов.",
  "The field should have at least 4 characters": "Поле должно содержать не менее 4 символов.",
  "The field should have at least 8 characters": "Поле должно содержать не менее 8 символов.",
  "New Password": "Новай пароль",
  "New Products": "Новые товары",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "Для этой страны нет доступных способов доставки. Приносим извинения, попробуйте выбрать другую страну.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "При попытке получить способы доставки произошла ошибка. Приносим извинения, попробуйте указать другие параметры доставки.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "При попытке выбрать этот способ доставки произошла ошибка. Приносим извинения, попробуйте другой способ доставки.",
  "We did not find products matching your request, try changing the filters":"Мы не нашли товары по твоему запросу, попробуй изменить фильтры",
  'Page not found': 'Страница не найдена',
  'Back to Home page': 'Вернуться на главную',
  'An error occurred': 'Произошла ошибка',
  "AllProductsFromCategory": "Все {categoryName}",
  "Show more": "Показать больше",
  "Show less": "Показать меньше",
  "Yes": "Да",
  "No": "Нет",
  "Apply filters": "Применить",
  "The coupon code isn't valid. Verify the code and try again.": "Код купона недействителен. Проверьте код и повторите попытку.",
  "From": "От",
  "To": "До",
  "Your customization": "Ваши настройки",
  "Passwords don't match":"Пароли не совпадают",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)":"Пароль должен иметь длину не менее 8 символов и содержать как минимум: 1 заглавную или строчную букву, 1 цифру или один специальный символ (например, . _ & ? и т. д.).",
  "Show all products":"Показвать все товары",
  "Select previously saved address":"Выберите ранее сохраненный адрес",
  "Enter different address":"Введите другой адрес",
  "You must confirm your account. Please check your email for the confirmation link.": "Вы должны подтвердить свою учетную запись. Пожалуйста, проверьте свою электронную почту, чтобы получить ссылку для подтверждения.",
  "Change Store":"Измените магазин",
  "Choose Currency":"Выберите Валюту",
  "Add a review":"Добавить отзыв",
  "Add to cart":"В корзину",
  "Title":"Название",
  "Name":"Имя",
  "Review":"Отзыв",
  "Add review":"Добавить отзыв",
  "Remove item from cart?":"Удалить товар из корзины?",
  "Remove items from cart?":"Удалить товары из корзины?",
  "Remove item from cart": "Удалить товар из корзины",
  "Are you sure you would like to remove this item from the shopping cart?":"Вы уверены, что хотите удалить этот товар из корзины?",
  "Are you sure you would like to remove all items from the shopping cart?":"Вы уверены, что хотите удалить все товары из корзины?",
  "Your cart is empty":"Ваша корзина пуста",
  "Are you sure?":"Вы уверены?",
  "{0} has been successfully removed from your cart":"Товар {0} был успешно удален из вашей корзины",
  "All items have been removed from your cart":"Все товары были удалены из вашей корзины",
  "Amount":"Количество",
  "Thank you for your order!":"Спасибо за ваш заказ!",
  "Your Purchase":"Ваша покупка",
  "Primary contacts for any questions":"Основные контакты по любым вопросам",
  "Your Account":"Ваш счет",
  "What can we improve":"Что мы можем улучшить",
  "Payment date":"Дата платежа",
  "The user password was changed successfully updated!":"Пароль пользователя изменен, успешно обновлено!",
  "The user account data was successfully updated!":" Данные учетной записи пользователя успешно обновлены!",
  "You submitted your review for moderation.": "Вы отправили свой отзыв на модерацию.",
  "Starting at": "Начинается с",
  "Offer": "Оферта",
  "Public offer": "Публичная оферта",
  "Site Map": "Карта сайта",
  "Bestsellers": "Бестселлеры",
  "You need to choose options for your item.": "Сначала выберите размер!",
  "Continue shopping": "Продолжить покупки",
  "Track status": "Отследить статус",
  "Preparing the order for shipment": 'Готовим заказ к&nbsp;отправке',
  "Thanks for your order": "Спасибо за заказ",
  "Delivery date": "Дата доставки",
  "Support": "Служба поддержки",
  "Wrong code": "Неверный код подтверждения",
  "More detailed": "Подробнее",
  "Product removed": "Товар удален",
  "Products removed": "Товары удалены",
  "Remove all": "Удалить все",
  "Sold out": "Распродано",
  "Related products": "Похожие товары",
  "Complete your image": "Дополни образ",
  "Delivery information has been sent to you via SMS": "Информация о заказе отправлена Вам по SMS",
  "Cancel order": "Отмена заказа",
  "Cancel this order": "Отменить заказ",
  "Unfortunately it did not work checkout": "К сожалению, не&nbsp;удалось оформить заказ",
  "Go to order": "Перейти к заказу",
  "Loyalty program": "Карта лояльности",
  "Still have questions?": "Остались вопросы?",
  "Contact us by phone": "Свяжитесь с нами по телефону",
  "or in messengers": "или в мессенджерах",
  "Write to us via chat or email": "Напишите нам в чат или на почту",
  "We will be happy to help!": "Будем рады помочь!",
  "Order canceled": "Заказ отменен",
  "Cost from": "Стоимость от",
  "Cost": "Стоимость —",
  "Sorry, the products are out of stock in the selected city": "К сожалению, в выбранном городе товары не в наличии",
  "Unable to place order: There are no source items with the in stock status Some of the products are out of stock.": "Не удалось оформить заказ, в корзине есть товары не в наличии",
  "Unable to place order: There are no source items with the in stock status\nSome of the products are out of stock.": "Не удалось оформить заказ, в корзине есть товары не в наличии",

  checkout: {
    cartProduct: {
      count: '{n} шт',
      remove: 'Удалить товар',
      canceled: 'Товар отменен',
      canceledCount: '{n} шт отменены|{n} шт отменена|{n} шт отменены|{n} шт отменены',
    },
    order: {
      header: {
        call: 'Заказ по телефону',
        title: 'заказ'
      },
      common: {
        list: {
          products: '{n} товаров|{n} товар|{n} товара|{n} товаров',
          discount: 'Скидка',
          shipping: {
            title: 'Доставка',
            notChosen: 'Не выбрано'
          }
        },
        couponCode: {
          placeholder: 'ПРОМОКОД',
          error: 'Промокод не действителен!',
          apply: 'ПРИМЕНИТЬ',
          cancel: 'ОТМЕНИТЬ'
        }
      },
      points: {
        title: 'Баланс карты Ready! Steady! Go!',
        number: '{n} &#8381;',
        placeholder: 'БАЛЛЫ',
        error: 'Вы можете списать {n} &#8381; с карты Ready! Steady! Go!',
        apply: 'СПИСАТЬ',
        cancel: 'ОТМЕНИТЬ'
      },
      total: 'Итого',
      list: {
        showMore: 'Состав заказа ({n})',
        change: 'Изменить'
      }
    },
    pickup: {
      point: {
        details: {
          storagePeriod: '{n} дней|{n} день|{n} дня|{n} дней',
        }
      }
    }
  },

  customer: {
    sidebar: {
      title: 'мой профиль',
      items: {
        profile: 'Мои данные',
        loyaltyCard: 'Карта лояльности',
        newsletter: 'Рассылка',
        orders: 'Мои заказы',
      },
      logOut: 'Выйти',
    },
    profile: {
      title: 'Мои данные'
    },
    newsletter: {
      title: 'Рассылка'
    },
    loyaltyCard: {
      title: 'Карта лояльности<span style="text-transform: initial;"  class="desktop-only"><br />Ready! Steady! Go!<span>',
      subtitle: `
        Номер твоей персональной<br />
        карты лояльности
      `,
      barcode: '№{n}',
      balance: '{n} &#8381;',
      percentage: {
        text: `
        Оплачивайте с&nbsp;карты Ready! Steady! Go от&nbsp;стоимости покупок<br />
        Карта лояльности Ready! Steady! Go! и&nbsp;карта лояльности GJ имеет общий баланс рублей.
        `
      },
      openBarcode: 'Открыть штрихкод'
    },
    orderHistory: {
      title: 'Мои заказы',
      empty: {
        subtitle: `
          Начнем нашу историю?<br>
          Скорее приступай к покупкам!
        `,
        action: 'перейти в каталог'
      },
      list: {
        id: {
          key: 'Заказ  №',
          value: '№{n}'
        },
        orderDate: {
          key: 'Дата заказа',
        },
        paymentType: {
          key: 'Оплата',
        },
        paymentTotal: {
          key: 'Сумма',
        },
        status: {
          key: 'Статус',
        },

        details: 'Детали'
      },
      details: {
        title: 'Детали заказа №{n}',
        list: {
          date: 'Дата заказа',
          deliveryDate: 'Ожидаемая дата доставки',
          status: 'Статус',
          shipping: 'Доставка',
          address: 'Адрес',
          trackCode: 'Трек-номер',
          trackCodeWithTitle: 'Трек-номер ({title})',
          paymentType: 'Оплата',
          products: 'Товары',
          discount: 'Скидка',
        },
        total: 'Итого',
        products: {
          showMore: 'Состав заказа'
        },
        back: 'к списку заказов',
        cancel: 'Отменить заказ №{n}?',
        successCancel: 'Заказ был успешно отменен'
      }
    }
  },
  date: {
    months: [
      'января',
      'февраля',
      'марта',
      'апреля',
      'мая',
      'июня',
      'июля',
      'августа',
      'сентября',
      'октября',
      'ноября',
      'декабря',
    ],
    short: '{day} {month} {year}',
    shortFrom: 'от {day} {month} {year}',
    shortTo: 'до {day} {month} {year}',
  }
};
