import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a301d77a = () => interopDefault(import('../modules/checkout/pages/Checkout.vue' /* webpackChunkName: "" */))
const _a5ccad1e = () => interopDefault(import('../modules/checkout/pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _37265a9c = () => interopDefault(import('../modules/checkout/pages/Checkout/Error.vue' /* webpackChunkName: "" */))
const _2e51f63e = () => interopDefault(import('../modules/customer/pages/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _3e458ace = () => interopDefault(import('../modules/customer/pages/MyAccount/profile' /* webpackChunkName: "" */))
const _e64cfb9c = () => interopDefault(import('../modules/customer/pages/MyAccount/loyalty-card' /* webpackChunkName: "" */))
const _1981f27a = () => interopDefault(import('../modules/customer/pages/MyAccount/order-history' /* webpackChunkName: "" */))
const _017aadef = () => interopDefault(import('../modules/customer/pages/MyAccount/order-history-details' /* webpackChunkName: "" */))
const _da7310ca = () => interopDefault(import('../pages/Care.vue' /* webpackChunkName: "pages/Care" */))
const _740316af = () => interopDefault(import('../pages/Cms.vue' /* webpackChunkName: "pages/Cms" */))
const _10153404 = () => interopDefault(import('../pages/Delivery.vue' /* webpackChunkName: "pages/Delivery" */))
const _03c11c08 = () => interopDefault(import('../pages/Faq.vue' /* webpackChunkName: "pages/Faq" */))
const _763b5a69 = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _52383708 = () => interopDefault(import('../pages/Loyaltyprogram.vue' /* webpackChunkName: "pages/Loyaltyprogram" */))
const _705cf942 = () => interopDefault(import('../pages/Map.vue' /* webpackChunkName: "pages/Map" */))
const _497652c2 = () => interopDefault(import('../pages/Offer.vue' /* webpackChunkName: "pages/Offer" */))
const _731f1fce = () => interopDefault(import('../pages/Page.vue' /* webpackChunkName: "pages/Page" */))
const _dbee6c28 = () => interopDefault(import('../pages/Payment.vue' /* webpackChunkName: "pages/Payment" */))
const _65c92e20 = () => interopDefault(import('../pages/Privacypolicy.vue' /* webpackChunkName: "pages/Privacypolicy" */))
const _d363caae = () => interopDefault(import('../pages/Returns.vue' /* webpackChunkName: "pages/Returns" */))
const _89fb853e = () => interopDefault(import('../pages/SizeGuide.vue' /* webpackChunkName: "pages/SizeGuide" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _a301d77a,
    name: "checkout",
    children: [{
      path: "thank-you",
      component: _a5ccad1e,
      name: "thank-you"
    }, {
      path: "error",
      component: _37265a9c,
      name: "error"
    }]
  }, {
    path: "/customer",
    component: _2e51f63e,
    meta: {"titleLabel":"My Account"},
    name: "customer",
    children: [{
      path: "profile",
      component: _3e458ace,
      meta: {"titleLabel":"customer.profile.title"},
      name: "customer-my-profile"
    }, {
      path: "loyalty-card",
      component: _e64cfb9c,
      meta: {"titleLabel":"customer.loyaltyCard.title"},
      name: "customer-loyalty-card"
    }, {
      path: "order-history",
      component: _1981f27a,
      meta: {"titleLabel":"customer.orderHistory.title"},
      name: "customer-order-history"
    }, {
      path: "order-history/:orderId",
      component: _017aadef,
      meta: {"titleLabel":"customer.orderHistory.title"},
      props: true,
      name: "customer-single-order"
    }]
  }, {
    path: "/Care",
    component: _da7310ca,
    name: "Care"
  }, {
    path: "/Cms",
    component: _740316af,
    name: "Cms"
  }, {
    path: "/Delivery",
    component: _10153404,
    name: "Delivery"
  }, {
    path: "/Faq",
    component: _03c11c08,
    name: "Faq"
  }, {
    path: "/Home",
    component: _763b5a69,
    name: "Home"
  }, {
    path: "/Loyaltyprogram",
    component: _52383708,
    name: "Loyaltyprogram"
  }, {
    path: "/Map",
    component: _705cf942,
    name: "Map"
  }, {
    path: "/Offer",
    component: _497652c2,
    name: "Offer"
  }, {
    path: "/Page",
    component: _731f1fce,
    name: "Page"
  }, {
    path: "/Payment",
    component: _dbee6c28,
    name: "Payment"
  }, {
    path: "/Privacypolicy",
    component: _65c92e20,
    name: "Privacypolicy"
  }, {
    path: "/Returns",
    component: _d363caae,
    name: "Returns"
  }, {
    path: "/SizeGuide",
    component: _89fb853e,
    name: "SizeGuide"
  }, {
    path: "/",
    component: _763b5a69,
    name: "home"
  }, {
    path: "/care",
    component: _da7310ca,
    name: "care"
  }, {
    path: "/size-guide",
    component: _89fb853e,
    name: "size-guide"
  }, {
    path: "/:slug+",
    component: _731f1fce,
    name: "page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
