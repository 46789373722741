import { defineStore } from 'pinia';
import { CustomerCustom } from '../composables/useUser/types';

interface CustomerStateBarcode {
  number: number | null;
  formatted: string | null;
}

interface CustomerState {
  user: CustomerCustom | null,
  isLoggedIn: boolean,
  barcode: CustomerStateBarcode,
}

export const useCustomerStore = defineStore('customer', {
  state: (): CustomerState => ({
    user: null,
    isLoggedIn: false,
    barcode: {
      number: null,
      formatted: null,
    },
  }),
  actions: {
    setIsLoggedIn(isLoggedIn: boolean) {
      this.isLoggedIn = isLoggedIn;
    },
    setBarcode({ number, formatted }: CustomerStateBarcode) {
      this.barcode.number = number;
      this.barcode.formatted = formatted;
    },
  },
});
