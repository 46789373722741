
























import AcceptCookies from '~/components/AcceptCookies.vue';
import LazyHydrate from 'vue-lazy-hydration';
import {
  useRoute, defineComponent, watch, ref,
} from '@nuxtjs/composition-api';
import { useUiState, useScreenHeight } from '~/composables';
import BottomNavigation from '~/components/BottomNavigation.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import LoadWhenVisible from '~/components/utils/LoadWhenVisible.vue';
import { AppHeader } from '~/components/Header';
import TrackGtmRoute from '~/components/gtm/track-gtm-route.vue';

export default defineComponent({
  name: 'FullwidthLayout',

  components: {
    TrackGtmRoute,
    AcceptCookies,
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    IconSprite,
    AppFooter: () => import(/* webpackPrefetch: true */ '~/components/AppFooter.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    WishlistSidebar: () => import(/* webpackPrefetch: true */ '~/modules/wishlist/components/WishlistSidebar.vue'),
    RsgLoginByCodeModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/login-by-code-modal'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    useScreenHeight();

    const route = useRoute();
    const {
      isCartSidebarOpen,
      // isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
    } = useUiState();

    const headerRerenderKey = ref(0);
    watch(() => route.value, () => {
      headerRerenderKey.value += 1;
    });

    return {
      headerRerenderKey,
      isCartSidebarOpen,
      // isWishlistSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
    };
  },
});
