import { Context } from '@nuxt/types';
import { AxiosRequestConfig, Method, AxiosInstance } from 'axios';

export class BaseRepository {
  api: AxiosInstance;

  context: Context;

  constructor(api: AxiosInstance, context: Context) {
    this.api = api;
    this.context = context;
  }

  get store() {
    return this.context.store;
  }

  get route() {
    return this.context.route;
  }

  get router() {
    return this.context.app.router;
  }

  get app() {
    return this.context.app;
  }

  get cookies() {
    return this.context.app.$cookies;
  }

  get config() {
    return this.context.$config;
  }

  get apiNamespace() {
    return '';
  }

  buildApiEndpoint(path): string {
    return path.startsWith('/') ? path : `${this.apiNamespace}/${path}`;
  }

  request<T>(config: AxiosRequestConfig) {
    return this.api.request<T>(config);
  }

  get<T>(config: AxiosRequestConfig) {
    const extendedConfig = { ...config, method: 'get' as Method };
    return this.request<T>(extendedConfig);
  }

  post<T>(config: AxiosRequestConfig) {
    const extendedConfig = { ...config, method: 'post' as Method };
    return this.request<T>(extendedConfig);
  }

  delete<T>(config: AxiosRequestConfig) {
    const extendedConfig = { ...config, method: 'delete' as Method };
    return this.request<T>(extendedConfig);
  }
}
