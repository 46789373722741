





































import { defineComponent } from '@nuxtjs/composition-api';
import { SfModal, SfButton } from '@storefront-ui/vue';
import { storeToRefs } from 'pinia';
import { useLocateCityDrawerState } from './locate-city-drawer.store';

export default defineComponent({
  name: 'DadataLocateCiteDrawerAccept',

  components: {
    SfModal,
    SfButton,
  },

  props: {
    city: {
      type: String,
      required: true,
    },
  },

  emits: ['acceptCity', 'denyCity'],

  setup(_, { emit }) {
    const store = useLocateCityDrawerState();
    const { acceptDrawerVisible } = storeToRefs(store);

    const handleAccept = () => {
      emit('acceptCity');
    };

    const handleDeny = () => {
      emit('denyCity');
    };

    const handleClose = () => handleAccept();

    return {
      acceptDrawerVisible,
      handleAccept,
      handleDeny,
      handleClose,
    };
  },
});
