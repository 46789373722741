import { readonly, ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import { useApi, FilterableAttributesQuery } from '~/composables';
import { sortingOptions } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import { perPageOptions } from '~/modules/catalog/category/composables/useFacet/perPageOptions';
import { createProductAttributeFilterInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeFilterInput';
import { createProductAttributeSortInput } from '~/modules/catalog/category/composables/useFacet/input/createProductAttributeSortInput';
import { Products } from '~/modules/GraphQL/types';
import { mockExtendedFilterableAttributes } from '~/integration/mockExtendedFilterableAttributes';
import getFacetDataQuery from './getFacetData.gql';
import type {
  UseFacetInterface,
  UseFacetErrors,
  UseFacetSearchResult,
  UseFacetFilterableAttributesResult,
  FacetSearchParams,
} from './useFacet';
import filterableAttributesGql from './filterableAttributes.gql';

/**
 * Allows searching for products with pagination, totals and sorting options.
 *
 * See the {@link UseFacetInterface} for a list of methods and values available in this composable.
 */
export function useFacet(): UseFacetInterface {
  const { query } = useApi();
  const loading = ref(false);
  const result = ref<UseFacetSearchResult>({ data: null, input: null });
  const filterableAttributesResult = ref<UseFacetFilterableAttributesResult>({ data: null });
  const error = ref<UseFacetErrors>({
    search: null,
    getFilterableAttributes: null,
  });
  const defaultItemsPerPage = 20;
  const search = async (params?: FacetSearchParams) => {
    Logger.debug('useFacet/search', params);

    result.value.input = params;
    try {
      loading.value = true;

      const pageSize = params.itemsPerPage ? params.itemsPerPage : defaultItemsPerPage;

      const productSearchParams: GetProductSearchParams = {
        pageSize,
        search: params.term ? params.term : '',
        filter: createProductAttributeFilterInput(params),
        sort: createProductAttributeSortInput(params.sort || ''),
        currentPage: params.page,
      };

      const { data } = await query<{ products: Products }>(getFacetDataQuery, productSearchParams);
      const products = data?.products ?? null;
      Logger.debug('[Result]:', { products });

      result.value.data = {
        items: products?.items ?? [],
        total: products?.total_count,
        availableSortingOptions: sortingOptions,
        perPageOptions,
        itemsPerPage: pageSize,
      };
      error.value.search = null;
    } catch (err) {
      error.value.search = err;
      Logger.error('useFacet/search', err);
    } finally {
      loading.value = false;
    }
  };

  const getFilterableAttributes = async () => {
    if (filterableAttributesResult.value.data) {
      return filterableAttributesResult.value;
    }

    try {
      loading.value = true;

      Logger.debug('[Magento] getFilterableAttributes');

      const { data } = await query(filterableAttributesGql) as { data: FilterableAttributesQuery };

      Logger.debug('[Result]:', { data });

      filterableAttributesResult.value = { data: mockExtendedFilterableAttributes() };
      error.value.getFilterableAttributes = null;
    } catch (err) {
      error.value.getFilterableAttributes = err;
      Logger.error('useFacet/getFilterableAttributes', err);
    } finally {
      loading.value = false;
    }

    return filterableAttributesResult.value;
  };

  return {
    search,
    result,

    getFilterableAttributes,
    filterableAttributesResult,

    error: readonly(error),
    loading: readonly(loading),
  };
}

export * from './useFacet';
export default useFacet;
