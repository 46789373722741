import { ProductDetailsQuery } from '~/modules/GraphQL/types';

export function mockExtendedProductDetails(products: ProductDetailsQuery['products']) {
  if (!Array.isArray(products?.items) || !products.items?.[0]) {
    return products;
  }

  const extendedProducts = products;

  // Расширение запроса для получения данных продукта моковыми данными
  // @ts-ignore Тип ProductDetailsQuery описан как пересечение типов только один из которых содержит attributes_details
  extendedProducts.items[0].attributes_details = [
    {
      code: 'sku',
      label: 'Артикул',
      value: 'GAC021552-1',
      option_id: 'GAC021552_1',
      __typename: 'AttributeDetails',
    },
    {
      code: 'structural_elements',
      label: 'Конструктивные элементы',
      value: 'манжеты',
      option_id: '315',
      __typename: 'AttributeDetails',
    },
    {
      code: 'color_on_tag',
      label: 'Цвет на бирке',
      value: 'сиреневый',
      option_id: '679',
      __typename: 'AttributeDetails',
    },
    {
      code: 'compound',
      label: 'Состав',
      value: '65% полиэстер, 35% хлопок',
      option_id: '1594',
      __typename: 'AttributeDetails',
    },
    {
      code: 'decor',
      label: 'Декор',
      value: 'ушки',
      option_id: '1199',
      __typename: 'AttributeDetails',
    },
    {
      code: 'drawing',
      label: 'Рисунок',
      value: 'принт',
      option_id: '79',
      __typename: 'AttributeDetails',
    },
    {
      code: 'pocket_type',
      label: 'Тип кармана',
      value: 'без карманов',
      option_id: '62',
      __typename: 'AttributeDetails',
    },
    {
      code: 'clasp',
      label: 'Застежка',
      value: 'молния',
      option_id: '94',
      __typename: 'AttributeDetails',
    },
    {
      code: 'sleeve_type',
      label: 'Тип рукава',
      value: 'со спущенной линией плеча',
      option_id: '320',
      __typename: 'AttributeDetails',
    },
    {
      code: 'model_parameters',
      label: 'Параметры модели',
      value: '84-62-91',
      option_id: '1330',
      __typename: 'AttributeDetails',
    },
    {
      code: 'size_on_model',
      label: 'Размер товара на модели',
      value: '170',
      option_id: '83',
      __typename: 'AttributeDetails',
    },
    {
      code: 'model_height',
      label: 'Рост модели на фото',
      value: '173',
      option_id: '569',
      __typename: 'AttributeDetails',
    },
    {
      code: 'fashion',
      label: 'Стиль',
      value: 'спортивный',
      option_id: '294',
      __typename: 'AttributeDetails',
    },
    {
      code: 'hood',
      label: 'Капюшон',
      value: 'несъёмный',
      option_id: '876',
      __typename: 'AttributeDetails',
    },
  ];

  // Удаление конфигурирумых параметров ЦВЕТ для скрытия ui на странице
  // UPDATE переопределять пустым значением нельзя, так как при добавлении в корзину товара запрос жалуется о невыборе данного параметра
  // @ts-ignore
  // extendedProducts.items[0].configurable_options = products.items[0]?.configurable_options?.filter((option) => option.attribute_code === 'size') || [];

  return extendedProducts;
}
