

















import {
  useGeolocation,
} from '~/composables';
import {
  defineComponent, computed, nextTick, watch,
} from '@nuxtjs/composition-api';
import { UserGeolocationData } from '~/stores/geolocation';
import DadataCityDrawerSearch from './locate-city-drawer-search.vue';
import DadataCityDrawerAccept from './locate-city-drawer-accept.vue';
import { useLocateCityDrawerState } from './locate-city-drawer.store';

export default defineComponent({
  name: 'DadataLocateCiteDrawer',

  components: {
    DadataCityDrawerSearch,
    DadataCityDrawerAccept,
  },
  setup() {
    const {
      location,
      toConfirmLocation,
      confirmLocation,
      setChoosedCity,
    } = useGeolocation();

    const store = useLocateCityDrawerState();
    const {
      setSearchDrawerVisibility, setAcceptDrawerVisibility,
    } = store;
    const computedCity = computed(() => location.value?.city || '');

    const scrollViewportToAcceptDrawer = () => {
      const isDesktopScreen = window.innerWidth >= 1024;
      if (!isDesktopScreen) {
        return;
      }

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    };

    watch(toConfirmLocation, (newValue) => {
      if (newValue) {
        scrollViewportToAcceptDrawer();
        setAcceptDrawerVisibility(true);
      }
    });

    const onAcceptCity = () => {
      confirmLocation();
      setAcceptDrawerVisibility(false);
    };

    const onDenyCity = () => {
      confirmLocation();
      setAcceptDrawerVisibility(false);
      nextTick(() => {
        setSearchDrawerVisibility(true);
      });
    };

    const onSearchClose = () => {
      setSearchDrawerVisibility(false);
    };

    const onChooseCity = (data: UserGeolocationData) => {
      setChoosedCity(data);
    };

    return {
      location,
      onChooseCity,
      onSearchClose,

      computedCity,
      onAcceptCity,
      onDenyCity,
    };
  },
});
