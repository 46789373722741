export default `
  mutation cancelOrder($orderNumber: String!) {
    cancelOrder(
        orderNumber: $orderNumber,
        reason: ""
    ) {
        error
        order {
            status
        }
    }
  }
`;
