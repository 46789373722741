import { onMounted, onUnmounted } from '@nuxtjs/composition-api';

export function useScreenHeight() {
  const getHeight = () => {
    let value = '100vh';

    if (window.innerWidth && window.innerWidth <= 1024) {
      value = `${window.innerHeight}px`;
    }
    document.documentElement.style.setProperty('--real100vh', value);
  };

  onMounted(() => {
    getHeight();
    window.addEventListener('resize', getHeight);
  });

  onUnmounted(() => {
    getHeight();
    window.addEventListener('resize', getHeight);
  });
}

export default useScreenHeight;
