import { ref, useContext } from '@nuxtjs/composition-api';
import {
  useCart, useGeolocation, useUser, useUserLoyaltyCard,
  ConfigurableCartItem, useConfig,
} from '~/composables';
import { EVENTS } from './data-layer';
import type { GTMTrackEvents } from './useGTM';
import customDimensions from './data-layer/custom-dimensions';
import { buildGTMCartProducts } from './builders';

export function useGTM() {
  const { $gtm } = useContext();
  const { config } = useConfig();
  const { user, load: loadUser } = useUser();
  const { location } = useGeolocation();
  const { load: loadCart, cart } = useCart();
  const { get: getCard, getNumber: getCardNumber } = useUserLoyaltyCard();
  const loyaltyCard = ref<string | null>(null);

  const deviceType = (() => {
    if (process.client) {
      const windowWidth = window.innerWidth;

      if (windowWidth > 1024) {
        return 'desktop';
      }

      if (windowWidth > 768) {
        return 'tablet';
      }

      return 'mobile';
    }
    return 'desktop';
  })();

  const getCustomDimensions = () => {
    const isAuth = !!user.value;

    return customDimensions({
      isAuth,
      customerId: user.value?.customer_id || 'anonymous',
      loyaltyCard: loyaltyCard.value,
      cdRegion: [location.value.regionWithType, location.value.settlementWithType].filter(Boolean).join(', ').trim(),
      cdCartId: cart.value ? cart.value?.id || null : null,
      name: isAuth ? [user.value?.firstname, user.value?.lastname].join(' ') : null,
      email: isAuth ? user.value.email : null,
      phone: isAuth ? user.value.telephone : null,
      deviceType,
    });
  };

  const getCartProducts = () => buildGTMCartProducts(cart.value?.items as unknown as ConfigurableCartItem[] || []);

  const getLoyaltyCard = async () => {
    if (!loyaltyCard.value && !!user.value) {
      const data = await (
        config.value.reward_points_enabled
          ? getCard()
          : getCardNumber()
      );
      loyaltyCard.value = data?.number || null;
    }
  };

  const addCurrencyCodeIfNeeded = (key: GTMTrackEvents) => {
    const localeCurrencyCode = 'RUB';

    return ([
      'view_item',
      'view_item_list',
      'view_cart',
      'add_to_cart',
      'remove_from_cart',
      'begin_checkout',
      'delivery_checkout',
      'payment_checkout',
      'finish_checkout',
      'purchase',
    ].includes(key) ? {
        currencyCode: localeCurrencyCode,
      } : {});
  };

  const addAuthMethodIfNeeded = (key: GTMTrackEvents) => {
    const authMethod = 'phone';

    return (['login', 'sign_up'].includes(key) ? {
      method: authMethod,
    } : {});
  };

  const addCartIdIfNeeded = (key: GTMTrackEvents) => {
    const cartId = cart.value ? cart.value?.id || null : null;

    return ([
      'view_cart',
      'begin_checkout',
      'delivery_checkout',
      'payment_checkout',
      'finish_checkout',
      'add_to_cart',
      'remove_from_cart',
    ].includes(key) ? {
        cartId,
      } : {});
  };

  const addCartProductsIfNeeded = (key: GTMTrackEvents) => {
    const products = getCartProducts();

    return ([
      'view_cart',
      'begin_checkout',
      'delivery_checkout',
      'payment_checkout',
      'finish_checkout',
    ].includes(key) ? {
        products,
      } : {});
  };

  const initCartGTM = async () => {
    if (!cart.value || !cart.value?.id) {
      await loadCart();
    }
  };

  const initUserGTM = async () => {
    if (!user.value) {
      await loadUser();
    }
  };

  // eslint-disable-next-line consistent-return
  async function trackEvent(key: GTMTrackEvents, data?: Record<string, unknown>) {
    if (!location.value) {
      return null;
    }

    await initUserGTM();
    await initCartGTM();
    await getLoyaltyCard();

    const userLayer = getCustomDimensions();
    const modificatedData = {
      ...data,

      ...addCurrencyCodeIfNeeded(key),
      ...addAuthMethodIfNeeded(key),
      ...addCartIdIfNeeded(key),
      ...addCartProductsIfNeeded(key),
    };

    // eslint-disable-next-line unicorn/prefer-reflect-apply
    const dataLayer = EVENTS[key]?.call(null, modificatedData);

    const pushedData = { ...dataLayer, ...userLayer, _clear: true };
    $gtm.push(pushedData);
  }

  return {
    trackEvent,
  };
}

export default useGTM;
