import type {
  PiniaPluginContext,
  StateTree,
  SubscriptionCallbackMutation,
  Store,
} from 'pinia';
import { Logger } from '~/helpers/logger';
import type { PersistenceOptions } from './persisted-state-plugin.types';

const DOCUMENT_SYNC_KEYS = new Set([
  'geolocationStore',
]);

const hydrateStore = (store: Store, { storeKey, serializer, storage }: PersistenceOptions) => {
  try {
    const fromStorage = storage?.getItem(storeKey);
    if (fromStorage) store.$patch(serializer?.deserialize(fromStorage));
  } catch (err) {
    Logger.error('persistedStatePlugin/hydrateStore', err);
  }
};

const persistState = (state: StateTree, { storeKey, serializer, storage }: PersistenceOptions) => {
  const data = serializer.serialize(state);
  storage.setItem(storeKey, data);
};

export function createPersistedStatePlugin(context: PiniaPluginContext) {
  const { options, store } = context;
  const storeKey = store.$id;

  const persistenceOptions: PersistenceOptions = {
    storeKey,
    serializer: {
      serialize: JSON.stringify,
      deserialize: JSON.parse,
    },
    storage: {
      getItem: localStorage.getItem.bind(localStorage),
      setItem: localStorage.setItem.bind(localStorage),
    },
  };

  const isPersistanceEnabled = options?.persist === true;

  const handleStorageUpdate = (event: StorageEvent) => {
    if (!DOCUMENT_SYNC_KEYS.has(event.key)) {
      return;
    }

    const { serializer } = persistenceOptions;

    const newValue = serializer.deserialize(event.newValue);
    const oldValue = store.location.value;

    if (newValue !== oldValue) {
      // TODO: Доработать обновление состояния без перезагрузки страницы
      window.location.reload();
      hydrateStore(store, persistenceOptions);
    }
  };

  if (isPersistanceEnabled) {
    hydrateStore(store, persistenceOptions);

    store.$subscribe(
      (_mutation: SubscriptionCallbackMutation<StateTree>, state: StateTree) => {
        persistState(state, persistenceOptions);
      },
      { detached: true },
    );

    window.addEventListener('storage', handleStorageUpdate);
  }
}
