import { Middleware } from '@nuxt/types';
import { useGeolocationStore } from '~/stores/geolocation';

const middleware : Middleware = (context) => {
  if (process.server) {
    const ip = (context.req.headers['x-forwarded-for'] as string)?.split(',')?.pop()
      || context.req.connection.remoteAddress;

    const { setIp } = useGeolocationStore(context.$pinia);
    if (ip) {
      setIp(ip);
    }
  }
};

export default middleware;
