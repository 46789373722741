import { defineStore } from 'pinia';

interface CurrentStoreState {
  store: string,
  cartSubscribed: boolean
}

export const useCurrentStore = defineStore('currentStore', {
  state: (): CurrentStoreState => ({
    store: '',
    cartSubscribed: false,
  }),
  actions: {
    setCartSubscribed() {
      this.cartSubscribed = true;
    },
  },
});
