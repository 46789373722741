import { Logger } from '~/helpers/logger';
import { VsfContext } from '~/composables/context';
import { CartCustom } from '../types';

export const applyPointsCommand = {
  execute: async (context: VsfContext, {
    currentCart,
    points,
    customHeaders = {},
  }) => {
    Logger.debug('[Magento]: Apply coupon on cart', {
      points,
      currentCart,
    });

    const { data, errors } = await context.$magento.api.customMutation({
      mutation: `mutation applyRewardPoints($cartId: String! $points: Int!) {
        applyRewardPoints(cart_id: $cartId, applied_points: $points) {
          applied_coupons {
            code
          }
          prices {
            subtotal_excluding_tax {
              value
            },
            subtotal_including_tax {
              value
            },
            applied_taxes {
              amount {
                value
              },
              label
            }
            discounts {
              amount {
                value
              },
              label
            }
            grand_total {
              value
            }
          }
          use_reward_points
          reward_points_applied
          max_allowed_point_to_apply
        }
      }`,
      mutationVariables: {
        cartId: currentCart.id,
        points,
      },
      customHeaders,
    });

    Logger.debug('[Result]:', { data, errors });

    const {
      applyRewardPoints: updatedCart,
    } = data as {
      applyRewardPoints: Partial<CartCustom>
    };

    return {
      updatedCart,
      errors,
    };
  },
};
