





















import {
  useGeolocation,
} from '~/composables';
import {
  computed, defineComponent, PropType,
} from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import DadataLocateCityDrawer from './locate-city-drawer/locate-city-drawer.vue';
import type { LocateCityVariantType } from './types';
import { useLocateCityDrawerState } from './locate-city-drawer/locate-city-drawer.store';

const LOCATE_CITY_VARIANTS_CLASS: Record<LocateCityVariantType, string> = {
  normal: 'locate-city_theme-normal',
  translucent: 'locate-city_theme-translucent',
};

export default defineComponent({
  name: 'DadataLocateCity',

  components: {
    DadataLocateCityDrawer,
  },

  props: {
    variant: {
      type: String as PropType<LocateCityVariantType>,
      default: 'normal',
    },
  },

  setup(props) {
    const { location, loading } = useGeolocation();
    const locateCityDrawerStore = useLocateCityDrawerState();
    const { setAcceptDrawerVisibility, setSearchDrawerVisibility } = locateCityDrawerStore;
    const { acceptDrawerVisible } = storeToRefs(locateCityDrawerStore);

    const handleCityChange = () => {
      setAcceptDrawerVisibility(false);
      setSearchDrawerVisibility(true);
    };

    const variantClass = computed(() => LOCATE_CITY_VARIANTS_CLASS[props.variant]);
    const isHidden = computed(() => !location.value);

    const locality = computed(() => {
      if (!location.value) {
        return '';
      }

      if (!location.value.settlement) {
        return location.value.city;
      }

      return location.value.city
        ? [location.value.city, location.value.settlementWithType].join(', ')
        : [location.value.regionWithType, location.value.settlementWithType].join(', ');
    });

    return {
      loading: computed(() => loading.value),
      isHidden,
      location,
      locality,
      handleCityChange,
      variantClass,
      acceptDrawerVisible,
    };
  },
});
