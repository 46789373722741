import type { GTMCustomDimensionsType } from '../useGTM';

// eslint-disable-next-line func-names
export default function ({
  customerId,
  isAuth,
  loyaltyCard,
  cdCartId,
  cdRegion,
  name,
  email,
  phone,
  deviceType,
}: GTMCustomDimensionsType) {
  return {
    tm_cd: {
      customer_id: customerId,
      is_auth: isAuth,
      loyalty_card: loyaltyCard,
      cd_cart_id: cdCartId,
      cd_region: cdRegion,
      name: name || null,
      email: email || null,
      phone: phone || null,
      device_type: deviceType,
    },
  };
}
