/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {
  computed,
  ref,
  useRouter,
  useContext,
  onMounted,
} from '@nuxtjs/composition-api';
import { getPrice } from '~/modules/catalog/product/getters/productGetters';
import { debounce } from 'lodash-es';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import {
  useUiNotification,
  useExternalCheckout,
  useImage,
  useProduct,
  useGTM,
} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useUser } from '~/modules/customer/composables/useUser';
import type { UseCartViewInterface } from '~/modules/checkout/composables/useCartView/useCartView';
import type { ConfigurableCartItem, BundleCartItem, CartItemInterface } from '~/modules/GraphQL/types';
import { ProductStockStatus } from '~/modules/GraphQL/types';
import { useCurrentStore } from '~/stores/currentStore';
import { buildGTMCartProducts, buildGTMCartProduct } from '~/composables/useGTM/builders';

/**
 * Allows loading and manipulating cart view.
 *
 * See the {@link UseCartViewInterface} for a list of methods and values available in this composable.
 */
export function useCartView(): UseCartViewInterface {
  const { localePath, app: { i18n } } = useContext();
  const { initializeCheckout } = useExternalCheckout();
  const { getMagentoImage, imageSizes } = useImage();
  const router = useRouter();
  const { getProductPath } = useProduct();
  const {
    cart,
    removeAllItems,
    removeItem,
    updateItemQty,
    load: loadCart,
    loading,
    error: cartError,
  } = useCart();
  const { isAuthenticated } = useUser();
  const { send: sendNotification, notifications } = useUiNotification();

  const products = computed(() => cartGetters
    .getItems(cart.value)
    .filter(Boolean)
    .map((item) => ({
      ...item,
      product: {
        ...item.product,
        ...[(item as ConfigurableCartItem).configured_variant ?? {}],
        original_sku: item.product.sku,
      },
    })));
  const totals = computed(() => cartGetters.getTotals(cart.value));
  const discountValue = computed(() => cartGetters.getDiscountAmount(cart.value));
  const discount = computed(() => (discountValue.value > 0 ? -discountValue.value : discountValue.value));
  const totalItems = computed(() => cartGetters.getTotalItems(cart.value));
  const getAttributes = (product: ConfigurableCartItem) => product.configurable_options || [];
  const getBundles = (product: BundleCartItem) => product.bundle_options?.map((b) => b.values).flat() || [];
  const isRemoveModalVisible = ref(false);
  const isRemoveAllModalVisible = ref(false);
  const itemToRemove = ref<CartItemInterface>();
  const currentStore = useCurrentStore();
  const { trackEvent } = useGTM();

  onMounted(() => {
    if (!cart.value || !cart.value?.id) {
      loadCart();
    }

    if (currentStore.cartSubscribed) {
      return;
    }

    currentStore.$subscribe(() => {
      if (cart.value && cart.value.id) {
        loadCart();
      }
    }, { detached: true });
    currentStore.setCartSubscribed();
  });

  const goToCheckout = async () => {
    const redirectUrl = initializeCheckout({ baseUrl: '/checkout' });
    await router.push(localePath(redirectUrl));
  };

  const showRemoveItemModal = ({ product }: { product: CartItemInterface }) => {
    if (notifications.value.length > 0) {
      notifications.value[0].dismiss();
    }

    isRemoveModalVisible.value = true;
    itemToRemove.value = product;
  };

  const showRemoveAllItemModal = () => {
    if (notifications.value.length > 0) {
      notifications.value[0].dismiss();
    }

    isRemoveAllModalVisible.value = true;
  };

  const removeItemAndSendNotification = async (product: CartItemInterface) => {
    await removeItem({ product });
    isRemoveModalVisible.value = false;

    sendNotification({
      id: Symbol('product_removed'),
      message: i18n.t('{0} has been successfully removed from your cart', {
        0: cartGetters.getItemName(
          product,
        ),
      }) as string,
      type: 'success',
      icon: null,
      persist: false,
      title: 'Продукт удален',
    });

    const typedProduct = product as ConfigurableCartItem;
    await trackEvent('remove_from_cart', {
      products: [buildGTMCartProduct(typedProduct)],
    });
  };

  const removeItemsAndSendNotification = async (removedProducts: CartItemInterface[]) => {
    await removeAllItems({ products: removedProducts });
    isRemoveAllModalVisible.value = false;

    sendNotification({
      id: Symbol('product_removed'),
      message: i18n.t('All items have been removed from your cart') as string,
      type: 'success',
      icon: null,
      persist: false,
      title: 'Продукты удалены',
    });

    const typedProducts = removedProducts as ConfigurableCartItem[];
    await trackEvent('remove_from_cart', {
      products: buildGTMCartProducts(typedProducts || []),
    });
  };

  const delayedUpdateItemQty = debounce(
    async (params) => {
      await updateItemQty(params);

      if (cartError.value.updateItemQty) {
        sendNotification({
          id: Symbol('update_item_qty_error'),
          title: 'Что-то пошло не так',
          message: cartError.value.updateItemQty?.message || 'Не удалось обновить количество товара',
          type: 'danger',
          icon: null,
          persist: false,
        });

        return;
      }

      const eventKey = params.quantity > params.product.quantity ? 'add_to_cart' : 'remove_from_cart';
      trackEvent(eventKey, {
        products: [
          buildGTMCartProduct(params.product, { quantity: Math.abs(params.quantity - params.product.quantity), debugKey: 'useCartView' }),
        ],
      });
    },
    1000,
  );

  const isInStock = (product: CartItemInterface) => cartGetters.getStockStatus(product) === ProductStockStatus.InStock;

  const hasOutOfStockProducts = computed(() => products.value.some((product) => !isInStock(product)));

  return {
    showRemoveItemModal,
    showRemoveAllItemModal,
    removeItemAndSendNotification,
    removeItemsAndSendNotification,
    delayedUpdateItemQty,
    goToCheckout,
    getAttributes,
    getBundles,
    isInStock,
    hasOutOfStockProducts,
    getMagentoImage,
    getProductPath,
    loading,
    isAuthenticated,
    products,
    isRemoveModalVisible,
    isRemoveAllModalVisible,
    itemToRemove,
    totals,
    totalItems,
    imageSizes,
    discount,
    cartGetters,
  };
}

export default useCartView;
export * from './useCartView';
