
import { useGTM } from '~/composables';
import {
  defineComponent, onMounted, useRoute, watch,
} from '@nuxtjs/composition-api';
import { useTrackGtmRoute } from './track-gtm-route.store';

export default defineComponent({
  name: 'RsgTrackGTMRoute',

  setup() {
    const { initialPage, setInitialPage } = useTrackGtmRoute();
    const { trackEvent } = useGTM();
    const route = useRoute();

    onMounted(() => {
      if (initialPage.value) {
        return trackEvent('page_view', { pagePath: route.value.path });
      }

      return setInitialPage(true);
    });

    watch(
      () => route.value,
      () => {
        if (initialPage.value) {
          trackEvent('page_view', { pagePath: route.value.path });
        }
      },
    );
  },

  render(createElement) {
    return createElement();
  },
});
