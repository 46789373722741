import type {
  DadataResponseApiType,
  DadataLocationResponseApiType,
  DadataLocationType,
  DadataSuggestionsResponseType,
} from '../types';
import { buildDadataSuggestAddressSuggestionsData } from './common';

function buildDadataLocateAddressLocation(rawLocation: DadataResponseApiType): DadataSuggestionsResponseType {
  const { value, data } = rawLocation;
  return {
    value,
    data: buildDadataSuggestAddressSuggestionsData(data),
  };
}

export function buildDadataLocateAddressResponse(response: DadataLocationResponseApiType): DadataLocationType {
  const { location } = response;

  return {
    location: buildDadataLocateAddressLocation(location),
  };
}
