import type {
  DadataResponseApiType,
  DadataSuggestionsResponseApiType,
  DadataSuggestionsResponseType,
  DadataSuggestionsType,
} from '../types';
import { buildDadataSuggestAddressSuggestionsData } from './common';

function buildDadataSuggestAddressSuggestions(rawSuggestions: DadataResponseApiType[]): DadataSuggestionsResponseType[] {
  return rawSuggestions.map<DadataSuggestionsResponseType>(({
    value,
    data,
  }) => ({
    value,
    data: buildDadataSuggestAddressSuggestionsData(data),
  }));
}

export function buildDadataSuggestAddressResponse(response: DadataSuggestionsResponseApiType): DadataSuggestionsType {
  const { suggestions } = response;

  return {
    suggestions: buildDadataSuggestAddressSuggestions(suggestions),
  };
}
