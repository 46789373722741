import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { SubscriptionStatusesEnum } from '~/modules/GraphQL/types';
import { useUiNotification } from '~/composables';
import { updateSubscriptionCommand } from './commands/updateSubscriptionCommand';
import type {
  UseNewsletterErrors,
  UseNewsletterInterface,
  UseNewsletterUpdateSubscriptionParams,
} from './useNewsletter';

/**
 * Allows updating the subscription status of
 * an email in the newsletter.
 *
 * See the {@link UseNewsletterInterface} for a list of methods and values available in this composable.
 */
export function useNewsletter(): UseNewsletterInterface {
  const context = useContext();
  const { send: sendNotification } = useUiNotification();
  const loading = ref(false);
  const status = ref<SubscriptionStatusesEnum | null>(null);
  const error = ref<UseNewsletterErrors>({
    updateSubscription: null,
  });

  const updateSubscription = async (params: UseNewsletterUpdateSubscriptionParams) => {
    Logger.debug('[Magento]: Update user newsletter subscription', { params });
    let result = SubscriptionStatusesEnum.Unsubscribed;

    try {
      loading.value = true;
      error.value.updateSubscription = null;

      result = await updateSubscriptionCommand.execute(context, params);
      status.value = result;

      if (SubscriptionStatusesEnum.Subscribed === result) {
        /*sendNotification({
          id: Symbol('subscription_subscribed'),
          message: 'Успешно',
          type: 'success',
          icon: 'check',
          persist: false,
          title: 'Вы подписались на рассылку',
        });*/
      } else {
       /* sendNotification({
          id: Symbol('subscription_subscribed'),
          message: 'Успешно',
          type: 'info',
          icon: 'check',
          persist: false,
          title: 'Вы уже подписаны на рассылку',
        });*/
      }
    } catch (err) {
      error.value.updateSubscription = err;
      Logger.error('useNewsletter/updateSubscription', err);

     /* sendNotification({
        id: Symbol('subscription_subscribed'),
        message: 'Ошибка',
        type: 'danger',
        icon: 'error',
        persist: false,
        title: 'Не удалось подписаться на рассылку',
      });*/
    } finally {
      loading.value = false;
    }

    return result;
  };

  return {
    updateSubscription,
    error: readonly(error),
    status: readonly(status),
    loading: readonly(loading),
  };
}

export * from './useNewsletter';
export default useNewsletter;