import { BaseRepository } from '../base';
import {
  buildDadataSuggestAddressResponse,
  buildDadataLocateAddressResponse,
  buildDadataSuggestCityLocationResponse,
  buildDadataLocateCityLocationResponse,
} from './builders';
import type {
  DadataSuggestionsPayloadType,
  DadataGeolocationPayloadType,
  DadataSuggestionsResponseApiType,
  DadataLocationResponseApiType,
  DadataGeolocationIpPayloadType,
} from './types';

function buildDadataHeaders(extendindHeaders: Record<string, string> = {}) {
  return {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...extendindHeaders,
  };
}

export class DadataRepository extends BaseRepository {
  get apiNamespace() {
    return '/dadata';
  }

  suggestAddress(data: DadataSuggestionsPayloadType) {
    const url = this.buildApiEndpoint('suggest/address');
    const headers = buildDadataHeaders();

    return this.api.request<DadataSuggestionsResponseApiType>({
      method: 'post', url, data, headers,
    })
      .then((response) => buildDadataSuggestAddressResponse(response.data))
      .catch((error) => { throw error; });
  }

  geolocateAddress([lat, lon]: DadataGeolocationPayloadType) {
    const url = this.buildApiEndpoint('geolocate/address');
    const headers = buildDadataHeaders();

    const data = {
      lat,
      lon,
      count: 1,
    };

    return this.api.request<DadataSuggestionsResponseApiType>({
      method: 'post', url, data, headers,
    }).then((response) => buildDadataSuggestAddressResponse(response.data))
      .catch((error) => { throw error; });
  }

  geolocateAddressFromIp() {
    const url = this.buildApiEndpoint('iplocate/address');
    const ipHeader = {
      'X-Forwarded-For': '127.0.0.1', // change later to real ip
    };
    const headers = buildDadataHeaders(ipHeader);

    const data = {
      ip: '127.0.0.1', // change later to real ip
    };

    return this.api.request<DadataLocationResponseApiType>({
      method: 'post', url, data, headers,
    }).then((response) => buildDadataLocateAddressResponse(response.data))
      .catch((error) => { throw error; });
  }

  suggestCity(data: DadataSuggestionsPayloadType) {
    const url = this.buildApiEndpoint('suggest/address');
    const headers = buildDadataHeaders();

    return this.api.request<DadataSuggestionsResponseApiType>({
      method: 'post', url, data, headers,
    })
      .then((response) => buildDadataSuggestCityLocationResponse(response.data))
      .catch((error) => { throw error; });
  }

  geolocateCity([lat, lon]: DadataGeolocationPayloadType) {
    const url = this.buildApiEndpoint('geolocate/address');
    const headers = buildDadataHeaders();

    const data = {
      lat,
      lon,
      count: 1,
    };

    return this.api.request<DadataSuggestionsResponseApiType>({
      method: 'post', url, data, headers,
    }).then((response) => buildDadataSuggestCityLocationResponse(response.data))
      .catch((error) => { throw error; });
  }

  geolocateCityFromIp(dto?: DadataGeolocationIpPayloadType) {
    const url = this.buildApiEndpoint('iplocate/address');
    const headers = buildDadataHeaders();
    const data = {
      ip: dto?.ip,
    };

    return this.api.request<DadataLocationResponseApiType>({
      method: 'post', url, data, headers,
    }).then((response) => buildDadataLocateCityLocationResponse(response.data))
      .catch((error) => { throw error; });
  }
}
