export const DEFAULT_CITIES = {
  MOSCOW: {
    fiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
    postalCode: '101000',
    countryISOCode: 'RU',
    regionWithType: 'г Москва',
    regionFiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
    regionKladrId: '7700000000000',
    areaWithType: null,
    city: 'Москва',
    cityFiasId: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
    cityKladrId: '7700000000000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 55.753_96,
    longitude: 37.620_393,
    value: 'г Москва',
  },
  SAINT_PETERSBURG: {
    fiasId: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
    postalCode: '190000',
    countryISOCode: 'RU',
    regionWithType: 'г Санкт-Петербург',
    regionFiasId: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
    regionKladrId: '7800000000000',
    areaWithType: null,
    city: 'Санкт-Петербург',
    cityFiasId: 'c2deb16a-0330-4f05-821f-1d09c93331e6',
    cityKladrId: '7800000000000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 59.939_084,
    longitude: 30.315_879,
    value: 'г Санкт-Петербург',
  },
  EKATERINBURG: {
    fiasId: '2763c110-cb8b-416a-9dac-ad28a55b4402',
    postalCode: '620000',
    countryISOCode: 'RU',
    regionWithType: 'Свердловская обл',
    regionFiasId: '92b30014-4d52-4e2e-892d-928142b924bf',
    regionKladrId: '6600000000000',
    areaWithType: null,
    city: 'Екатеринбург',
    cityFiasId: '2763c110-cb8b-416a-9dac-ad28a55b4402',
    cityKladrId: '6600000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 56.838_607,
    longitude: 60.605_514,
    value: 'г Екатеринбург',
  },
  NIZHNY_NOVGOROD: {
    fiasId: '555e7d61-d9a7-4ba6-9770-6caa8198c483',
    postalCode: '603000',
    countryISOCode: 'RU',
    regionWithType: 'Нижегородская обл',
    regionFiasId: '88cd27e2-6a8a-4421-9718-719a28a0a088',
    regionKladrId: '6600000000000',
    areaWithType: null,
    city: 'Нижний Новгород',
    cityFiasId: '555e7d61-d9a7-4ba6-9770-6caa8198c483',
    cityKladrId: '6600000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 56.324_133,
    longitude: 44.005_299,
    value: 'г Нижний Новгород',
  },
  KRASNODAR: {
    fiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
    postalCode: '350000',
    countryISOCode: 'RU',
    regionWithType: 'Краснодарский край',
    regionFiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
    regionKladrId: '2300000000000',
    areaWithType: null,
    city: 'Краснодар',
    cityFiasId: '7dfa745e-aa19-4688-b121-b655c11e482f',
    cityKladrId: '2300000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 45.040_216,
    longitude: 38.975_996,
    value: 'г Краснодар',
  },
  SAMARA: {
    fiasId: 'bb035cc3-1dc2-4627-9d25-a1bf2d4b936b',
    postalCode: '443000',
    countryISOCode: 'RU',
    regionWithType: 'Самарская обл',
    regionFiasId: 'df3d7359-afa9-4aaa-8ff9-197e73906b1c',
    regionKladrId: '6300000000000',
    areaWithType: null,
    city: 'Самара',
    cityFiasId: 'bb035cc3-1dc2-4627-9d25-a1bf2d4b936b',
    cityKladrId: '6300000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 53.195_096,
    longitude: 50.106_868,
    value: 'г Самара',
  },
  NOVOSIBIRSK: {
    fiasId: '8dea00e3-9aab-4d8e-887c-ef2aaa546456',
    postalCode: '630000',
    countryISOCode: 'RU',
    regionWithType: 'Новосибирская обл',
    regionFiasId: '1ac46b49-3209-4814-b7bf-a509ea1aecd9',
    regionKladrId: '5400000000000',
    areaWithType: null,
    city: 'Новосибирск',
    cityFiasId: '8dea00e3-9aab-4d8e-887c-ef2aaa546456',
    cityKladrId: '5400000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 55.028_141,
    longitude: 82.921_117,
    value: 'г Новосибирск',
  },
  KAZAN: {
    fiasId: '93b3df57-4c89-44df-ac42-96f05e9cd3b9',
    postalCode: '420000',
    countryISOCode: 'RU',
    regionWithType: 'Респ Татарстан',
    regionFiasId: '0c089b04-099e-4e0e-955a-6bf1ce525f1a',
    regionKladrId: '1600000000000',
    areaWithType: null,
    city: 'Казань',
    cityFiasId: '93b3df57-4c89-44df-ac42-96f05e9cd3b9',
    cityKladrId: '1600000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 55.794_438,
    longitude: 49.111_451,
    value: 'г Казань',
  },
  CHELYABINSK: {
    fiasId: 'a376e68d-724a-4472-be7c-891bdb09ae32',
    postalCode: '454000',
    countryISOCode: 'RU',
    regionWithType: 'Челябинская обл',
    regionFiasId: '27eb7c10-a234-44da-a59c-8b1f864966de',
    regionKladrId: '7400000000000',
    areaWithType: null,
    city: 'Челябинск',
    cityFiasId: 'a376e68d-724a-4472-be7c-891bdb09ae32',
    cityKladrId: '7400000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 55.160_283,
    longitude: 61.400_856,
    value: 'г Челябинск',
  },
  IRKUTSK: {
    fiasId: '8eeed222-72e7-47c3-ab3a-9a553c31cf72',
    postalCode: '664000',
    countryISOCode: 'RU',
    regionWithType: 'Иркутская обл',
    regionFiasId: '6466c988-7ce3-45e5-8b97-90ae16cb1249',
    regionKladrId: '3800000000000',
    areaWithType: null,
    city: 'Иркутск',
    cityFiasId: '8eeed222-72e7-47c3-ab3a-9a553c31cf72',
    cityKladrId: '3800000300000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 52.286_387,
    longitude: 104.280_66,
    value: 'г Иркутск',
  },
  UFA: {
    fiasId: '7339e834-2cb4-4734-a4c7-1fca2c66e562',
    postalCode: '450000',
    countryISOCode: 'RU',
    regionWithType: 'Респ Башкортостан',
    regionFiasId: '6f2cbfd8-692a-4ee4-9b16-067210bde3fc',
    regionKladrId: '0200000000000',
    areaWithType: null,
    city: 'Уфа',
    cityFiasId: '7339e834-2cb4-4734-a4c7-1fca2c66e562',
    cityKladrId: '0200000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 54.734_856,
    longitude: 55.957_856,
    value: 'г Уфа',
  },
  KRASNOYARSK: {
    fiasId: '9b968c73-f4d4-4012-8da8-3dacd4d4c1bd',
    postalCode: '660000',
    countryISOCode: 'RU',
    regionWithType: 'Красноярский край',
    regionFiasId: 'db9c4f8b-b706-40e2-b2b4-d31b98dcd3d1',
    regionKladrId: '2400000000000',
    areaWithType: null,
    city: 'Красноярск',
    cityFiasId: '9b968c73-f4d4-4012-8da8-3dacd4d4c1bd',
    cityKladrId: '2400000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 56.009_477,
    longitude: 92.852_449,
    value: 'г Красноярск',
  },
  ROSTOV_NA_DONU: {
    fiasId: 'c1cfe4b9-f7c2-423c-abfa-6ed1c05a15c5',
    postalCode: '344000',
    countryISOCode: 'RU',
    regionWithType: 'Ростовская обл',
    regionFiasId: 'f10763dc-63e3-48db-83e1-9c566fe3092b',
    regionKladrId: '6100000000000',
    areaWithType: null,
    city: 'Ростов-на-Дону',
    cityFiasId: 'c1cfe4b9-f7c2-423c-abfa-6ed1c05a15c5',
    cityKladrId: '6100000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 47.222_531,
    longitude: 39.718_705,
    value: 'г Ростов-на-Дону',
  },
  YAROSLAVL: {
    fiasId: '6b1bab7d-ee45-4168-a2a6-4ce2880d90d3',
    postalCode: '150000',
    countryISOCode: 'RU',
    regionWithType: 'Ярославская обл',
    regionFiasId: 'a84b2ef4-db03-474b-b552-6229e801ae9b',
    regionKladrId: '7600000000000',
    areaWithType: null,
    city: 'Ярославль',
    cityFiasId: '6b1bab7d-ee45-4168-a2a6-4ce2880d90d3',
    cityKladrId: '7600000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 57.621_562,
    longitude: 39.897_822,
    value: 'г Ярославль',
  },
  VLADIVOSTOK: {
    fiasId: '7b6de6a5-86d0-4735-b11a-499081111af8',
    postalCode: '690000',
    countryISOCode: 'RU',
    regionWithType: 'Приморский край',
    regionFiasId: '43909681-d6e1-432d-b61f-ddac393cb5da',
    regionKladrId: '2500000000000',
    areaWithType: null,
    city: 'Владивосток',
    cityFiasId: '7b6de6a5-86d0-4735-b11a-499081111af8',
    cityKladrId: '2500000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 43.116_391,
    longitude: 131.882_421,
    value: 'г Владивосток',
  },
  TULA: {
    fiasId: 'b2601b18-6da2-4789-9fbe-800dde06a2bb',
    postalCode: '300000',
    countryISOCode: 'RU',
    regionWithType: 'Тульская обл',
    regionFiasId: 'd028ec4f-f6da-4843-ada6-b68b3e0efa3d',
    regionKladrId: '7100000000000',
    areaWithType: null,
    city: 'Тула',
    cityFiasId: 'b2601b18-6da2-4789-9fbe-800dde06a2bb',
    cityKladrId: '7100000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 54.191_982,
    longitude: 37.615_349,
    value: 'г Тула',
  },
  SARATOV: {
    fiasId: 'bf465fda-7834-47d5-986b-ccdb584a85a6',
    postalCode: '410000',
    countryISOCode: 'RU',
    regionWithType: 'Саратовская обл',
    regionFiasId: 'df594e0e-a935-4664-9d26-0bae13f904fe',
    regionKladrId: '6400000000000',
    areaWithType: null,
    city: 'Саратов',
    cityFiasId: 'bf465fda-7834-47d5-986b-ccdb584a85a6',
    cityKladrId: '6400000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 51.533_557,
    longitude: 46.034_257,
    value: 'г Саратов',
  },
  PERM: {
    fiasId: 'a309e4ce-2f36-4106-b1ca-53e0f48a6d95',
    postalCode: '614000',
    countryISOCode: 'RU',
    regionWithType: 'Саратовская обл',
    regionFiasId: '4f8b1a21-e4bb-422f-9087-d3cbf4bebc14',
    regionKladrId: '5900000000000',
    areaWithType: null,
    city: 'Пермь',
    cityFiasId: 'a309e4ce-2f36-4106-b1ca-53e0f48a6d95',
    cityKladrId: '5900000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 58.010_259,
    longitude: 56.234_195,
    value: 'г Пермь',
  },
  TYUMEN: {
    fiasId: '9ae64229-9f7b-4149-b27a-d1f6ec74b5ce',
    postalCode: '625000',
    countryISOCode: 'RU',
    regionWithType: 'Тюменская обл',
    regionFiasId: '54049357-326d-4b8f-b224-3c6dc25d6dd3',
    regionKladrId: '7200000000000',
    areaWithType: null,
    city: 'Тюмень',
    cityFiasId: '9ae64229-9f7b-4149-b27a-d1f6ec74b5ce',
    cityKladrId: '7200000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 57.153_033,
    longitude: 65.534_328,
    value: 'г Тюмень',
  },
  OMSK: {
    fiasId: '140e31da-27bf-4519-9ea0-6185d681d44e',
    postalCode: '644000',
    countryISOCode: 'RU',
    regionWithType: 'Омская обл',
    regionFiasId: '05426864-466d-41a3-82c4-11e61cdc98ce',
    regionKladrId: '5500000000000',
    areaWithType: null,
    city: 'Омск',
    cityFiasId: '140e31da-27bf-4519-9ea0-6185d681d44e',
    cityKladrId: '5500000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 54.984_912,
    longitude: 73.367_525,
    value: 'г Омск',
  },
  VORONEZH: {
    fiasId: '5bf5ddff-6353-4a3d-80c4-6fb27f00c6c1',
    postalCode: '394000',
    countryISOCode: 'RU',
    regionWithType: 'Воронежская обл',
    regionFiasId: 'b756fe6b-bbd3-44d5-9302-5bfcc740f46e',
    regionKladrId: '3600000000000',
    areaWithType: null,
    city: 'Воронеж',
    cityFiasId: '5bf5ddff-6353-4a3d-80c4-6fb27f00c6c1',
    cityKladrId: '3600000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 51.659_306,
    longitude: 39.196_854,
    value: 'г Воронеж',
  },
  VOLGOGRAD: {
    fiasId: 'a52b7389-0cfe-46fb-ae15-298652a64cf8',
    postalCode: '400066',
    countryISOCode: 'RU',
    regionWithType: 'Волгоградская обл',
    regionFiasId: 'da051ec8-da2e-4a66-b542-473b8d221ab4',
    regionKladrId: '3400000000000',
    areaWithType: null,
    city: 'Волгоград',
    cityFiasId: 'a52b7389-0cfe-46fb-ae15-298652a64cf8',
    cityKladrId: '3400000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 48.707_103,
    longitude: 44.516_939,
    value: 'г Волгоград',
  },
  SOCHI: {
    fiasId: '79da737a-603b-4c19-9b54-9114c96fb912',
    postalCode: '354000',
    countryISOCode: 'RU',
    regionWithType: 'Волгоградская обл',
    regionFiasId: 'd00e1013-16bd-4c09-b3d5-3cb09fc54bd8',
    regionKladrId: '2300000000000',
    areaWithType: null,
    city: 'Сочи',
    cityFiasId: '79da737a-603b-4c19-9b54-9114c96fb912',
    cityKladrId: '2300000700000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 43.585_525,
    longitude: 39.723_062,
    value: 'г Сочи',
  },
  KHABAROVSK: {
    fiasId: 'a4859da8-9977-4b62-8436-4e1b98c5d13f',
    postalCode: '680000',
    countryISOCode: 'RU',
    regionWithType: 'Хабаровский край',
    regionFiasId: '7d468b39-1afa-41ec-8c4f-97a8603cb3d4',
    regionKladrId: '2700000000000',
    areaWithType: null,
    city: 'Хабаровск',
    cityFiasId: 'a4859da8-9977-4b62-8436-4e1b98c5d13f',
    cityKladrId: '2700000100000',
    settlement: null,
    settlementWithType: null,
    settlementFiasId: null,
    settlementKladrId: null,
    latitude: 48.464_805_5,
    longitude: 135.059_860_6,
    value: 'г Хабаровск',
  },
};
