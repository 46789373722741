/**
 * Правило плюрализации для славянских языков
 * @link https://kazupon.github.io/vue-i18n/ru/guide/pluralization.html
 * @param choice {number} индекс выбора, переданный в $tc: `$tc('path.to.rule', choiceIndex)`
 * @param choicesLength {number} общее количество доступных вариантов
 * @returns финальный индекс для выбора соответственного варианта слова
 */
export const ruRule = (choice, choicesLength) => {
  if (choice === 0) {
    return 0;
  }

  const teen = choice % 100 > 10 && choice % 100 < 20;
  const endsWithOne = choice % 10 === 1;

  if (choicesLength < 4) {
    return !teen && endsWithOne ? 1 : 2;
  }
  if (!teen && endsWithOne) {
    return 1;
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return 2;
  }

  return choicesLength < 4 ? 2 : 3;
};
