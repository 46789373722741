import {
  computed,
  ref,
  shallowReactive,
  watch,
} from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';
import { PickUpPoint } from '~/modules/checkout/composables/usePickUp';

export const METHODS = {
  CDEK: 'cdekpickup',
  RUSSIAN_POST: 'russianpost',
};

export const METHODS_VIEW = {
  [METHODS.CDEK]: 'CDEK',
  [METHODS.RUSSIAN_POST]: 'ПОЧТА РОССИИ',
};

export const POINTS_TYPES_DEFAULT = {
  POSTAMAT: 'POSTAMAT',
  PVZ: 'PVZ',
};

export const POINTS_TYPES_CDEK = {
  [POINTS_TYPES_DEFAULT.POSTAMAT]: [POINTS_TYPES_DEFAULT.POSTAMAT],
  [POINTS_TYPES_DEFAULT.PVZ]: [POINTS_TYPES_DEFAULT.PVZ],
};

export const POINTS_TYPES_RUSSIAN_POST = {
  [POINTS_TYPES_DEFAULT.POSTAMAT]: ['Почтомат'],
  [POINTS_TYPES_DEFAULT.PVZ]: ['ГОПС', 'СОПС'],
};

export const POINTS_TYPES_BY_METHODS = {
  [METHODS.CDEK]: POINTS_TYPES_CDEK,
  [METHODS.RUSSIAN_POST]: POINTS_TYPES_RUSSIAN_POST,
};

export const usePickUpPointsStore = defineStore('pick-up-points', () => {
  const isModalOpened = ref(false);
  const isLoading = ref(false);
  const isMapInitialized = ref(false);

  const currentMethod = ref<string | null>(null);
  const methods = ref([]);
  const methodsView = computed(() => methods.value.map((method) => ({
    title: METHODS_VIEW[method],
    code: method,
  })));

  const point = ref<PickUpPoint | null>(null);
  const points = shallowReactive({});
  const pointsDetails = ref([]);
  const pointsTypesSelected = ref([]);

  const bounds = ref([[0, 0], [0, 0]]);

  watch(() => isModalOpened.value, (opened) => {
    const window = document.querySelector('html');

    if (opened) {
      window.style.setProperty('overflow', 'hidden');
    } else {
      window.style.removeProperty('overflow');
    }
  });

  return {
    isModalOpened,
    isLoading,
    isMapInitialized,

    currentMethod,
    methods,
    methodsView,

    point,
    points,
    pointsDetails,
    pointsTypesSelected,

    bounds,
  };
});
