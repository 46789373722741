
























import AcceptCookies from '~/components/AcceptCookies.vue';
import LazyHydrate from 'vue-lazy-hydration';
import { useRoute, defineComponent, computed } from '@nuxtjs/composition-api';
import { useUiState, useScreenHeight } from '~/composables';
import IconSprite from '~/components/General/IconSprite.vue';
import { usePickUpPointsStore } from '~/modules/checkout/stores/pick-up-points';
import cartGetters from '~/modules/checkout/getters/cartGetters';
import useCart from '~/modules/checkout/composables/useCart';
import TrackGtmRoute from '~/components/gtm/track-gtm-route.vue';

export default defineComponent({
  name: 'CheckoutLayout',

  components: {
    TrackGtmRoute,
    AcceptCookies,
    LazyHydrate,
    IconSprite,
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    RsgLoginByCodeModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/login-by-code-modal'),
    RsgPickUpPointsModal: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/pick-up-points-modal'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    useScreenHeight();

    const route = useRoute();
    const {
      isCartSidebarOpen, isLoginModalOpen, toggleLoginModal,
    } = useUiState();

    const { cart } = useCart();

    const pickUpPointsStore = usePickUpPointsStore();

    const selectedShippingMethod = computed(() => cartGetters.getSelectedShippingMethod(cart.value));
    const selectedShippingMethodType = computed(() => (
      selectedShippingMethod.value
        ? cartGetters.getShippingMethodType(selectedShippingMethod.value)
        : null
    ));

    const pickupPointsMethods = computed(() => (
      selectedShippingMethod.value?.method_code
        ? [selectedShippingMethod.value?.method_code]
        : null));

    const totals = computed(() => cartGetters.getTotals(cart.value));

    const onPickUpPointsModalSelect = (pointSelected) => {
      pickUpPointsStore.point = pointSelected;
      pickUpPointsStore.isModalOpened = false;
    };

    return {
      isCartSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
      pickupPointsMethods,
      totals,
      selectedShippingMethodType,
      onPickUpPointsModalSelect,
    };
  },
});
