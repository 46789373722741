import { readonly, ref, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { mockExtendedLoyaltyCardNumber } from '~/integration/mockExtendedLoyaltyCardNumber';
import type {
  UseUserLoyaltyCardErrors,
  UseUserLoyaltyCardInterface,
} from './useUserLoyaltyCard';

import getLoyaltyCardGql from './getLoyaltyCardGql.gql';
import getLoyaltyCardNumberGql from './getLoyaltyCardNumberGql.gql';
import { CustomerLoyaltyCard, CustomerLoyaltyCardNumber } from './types';

/**
 * Allows fetching customer loyalty card.
 *
 * See the {@link UseUserLoyaltyCardInterface} for a list of methods and values available in this composable.
 */
export function useUserLoyaltyCard(): UseUserLoyaltyCardInterface {
  const { app } = useContext();
  const loading = ref(false);
  const error = ref<UseUserLoyaltyCardErrors>({
    get: null,
  });

  const get = async (options?: { forceBalanceSync?: boolean }) => {
    let results = null;

    try {
      loading.value = true;

      Logger.debug('[Magento] get user\'s loyalty card');

      const { data } = await app.$vsf.$magento.api.customQuery({
        query: getLoyaltyCardGql,
        queryVariables: options?.forceBalanceSync ? { forceBalanceSync: options.forceBalanceSync } : {},
      }) as { data: { getDiscountCard: CustomerLoyaltyCard } };

      Logger.debug('[Result]:', { data });

      results = data?.getDiscountCard ?? null;
      error.value.get = null;
    } catch (err) {
      error.value.get = err;
      Logger.error('useUserLoyaltyCard/get', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  const getNumber = async () => {
    let results = null;

    try {
      loading.value = true;

      Logger.debug('[Magento] get user\'s loyalty card number');

      // const { data } = await app.$vsf.$magento.api.customQuery({
      //   query: getLoyaltyCardNumberGql,
      // }) as { data: { getDiscountCard: CustomerLoyaltyCardNumber } };

      const data = mockExtendedLoyaltyCardNumber();

      Logger.debug('[Result]:', { data });

      results = data?.getDiscountCard ?? null;
      error.value.get = null;
    } catch (err) {
      error.value.get = err;
      Logger.error('useUserLoyaltyCard/getNumber', err);
    } finally {
      loading.value = false;
    }

    return results;
  };

  return {
    get,
    getNumber,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useUserLoyaltyCard;
export * from './useUserLoyaltyCard';
