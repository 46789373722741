









































import { defineComponent } from '@nuxtjs/composition-api';
import type { PropType } from '@nuxtjs/composition-api';
import { SfButton } from '@storefront-ui/vue';
import type { NuxtError } from '@nuxt/types';
import { AppHeader, useHeaderState } from '~/components/Header';
import { useUiState, useScreenHeight } from '~/composables';
import TrackGtmRoute from '~/components/gtm/track-gtm-route.vue';

export default defineComponent({
  components: {
    TrackGtmRoute,
    AppHeader,
    SfButton,
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    RsgLoginByCodeModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/login-by-code-modal'),
  },
  layout: 'empty',
  props: {
    error: {
      type: Object as PropType<NuxtError>,
      required: true,
    },
  },
  setup() {
    useScreenHeight();

    const { setVariant } = useHeaderState();
    const {
      isCartSidebarOpen, isLoginModalOpen, toggleLoginModal,
    } = useUiState();

    setVariant('light');

    return {
      isCartSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
    };
  },
});
