

























































































import {
  SfOverlay, SfHeader, SfButton, SfBadge,
} from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';
import {
  computed,
  ref,
  defineComponent,
  useRouter,
  useContext,
  onMounted,
  useFetch,
  watch,
} from '@nuxtjs/composition-api';
import DadataLocateCity from '~/components/dadata/locate-city';
import ContentWrapper from '~/components/utils/ContentWrapper.vue';
import { useCategory } from '~/modules/catalog/category/composables/useCategory';
import {
  useUiHelpers,
  useUiState,
} from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
// import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useUser } from '~/modules/customer/composables/useUser';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import SvgImage from '~/components/General/SvgImage.vue';
import TopBar from '~/components/TopBar/TopBar.vue';
import { useCurrentStore } from '~/stores/currentStore';
import { storeToRefs } from 'pinia';
import HeaderNavigation from './Navigation/HeaderNavigation.vue';
import HeaderLogo from './Logo/HeaderLogo.vue';

import type { HeaderVariantType } from './types';
import { useHeaderState } from './useHeaderState';

const HEADER_VARIANTS_CLASS: Record<HeaderVariantType, string> = {
  dark: 'app-header_dark',
  light: 'app-header_light',
};
const HEADER_FLOW_LAYOUT_CLASS = 'app-header_floating';

export default defineComponent({
  components: {
    HeaderNavigation,
    ContentWrapper,
    SfHeader,
    SfOverlay,
    HeaderLogo,
    SvgImage,
    SfButton,
    SfBadge,
    DadataLocateCity,
    TopBar,
    HeaderSupport: () => import('./Support/HeaderSupport.vue'),
    SearchBar: () => import('./SearchBar/SearchBar.vue'),
    SearchResults: () => import(
      /* webpackPrefetch: true */ './SearchBar/SearchResults.vue'
    ),
  },

  setup() {
    const router = useRouter();
    const { app } = useContext();
    const { toggleCartSidebar, toggleLoginModal } = useUiState();
    const { setTermForUrl, getCatLink } = useUiHelpers();
    const { isAuthenticated, load } = useUser();
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    // const { loadItemsCount: loadWishlistItemsCount } = useWishlist();
    const { categories: categoryList, load: categoriesListLoad } = useCategory();

    const currentStore = useCurrentStore();
    const { store } = storeToRefs(currentStore);

    onMounted(async () => {
      await load();
    });

    const {
      variant: headerVariant,
      floating: headerFloating,
      cityLocatorVariant,
      logoSvgVariant,
    } = useHeaderState();

    const isSearchOpen = ref(false);
    const productSearchResults = ref<ProductInterface[] | null>(null);

    const headerThemeConfig = computed(() => {
      const searchOpenedConfig = {
        variantClass: HEADER_VARIANTS_CLASS.dark,
        flowLayoutClass: headerFloating ? HEADER_FLOW_LAYOUT_CLASS : '',
        locatorVariant: 'normal',
        logoIconColor: 'black',
        accountIconHtmlId: 'header_account_transparent',
        cartIconHtmlId: 'header_cart',
      };

      const config = {
        variantClass: HEADER_VARIANTS_CLASS[headerVariant],
        flowLayoutClass: headerFloating ? HEADER_FLOW_LAYOUT_CLASS : '',
        locatorVariant: cityLocatorVariant,
        logoIconColor: logoSvgVariant,
        accountIconHtmlId: !isAuthenticated.value ? 'header_account_transparent' : 'header_account-authed_transparent',
        cartIconHtmlId: `header_cart${headerVariant === 'dark' ? '' : '_white'}`,
      };

      return isSearchOpen.value ? searchOpenedConfig : config;
    });

    const handleAccountClick = async () => {
      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer-my-profile' }));
      } else {
        toggleLoginModal();
      }
    };

    const categoryTree = ref<CategoryTree[]>([]);
    const { fetch: fetchCategoryTree } = useFetch(async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children
        .filter((category) => category.include_in_menu);
    });

    fetchCategoryTree();

    watch(() => store.value, async () => {
      await categoriesListLoad({ pageSize: 20 });

      categoryTree.value = categoryList.value?.[0]?.children
        .filter((category) => category.include_in_menu);
    });

    onMounted(async () => {
      if (app.$device.isDesktop) {
        await loadCartTotalQty();
        // eslint-disable-next-line promise/catch-or-return
        // await loadWishlistItemsCount();
      }
    });

    const handleSearchResultsClose = () => {
      isSearchOpen.value = false;
    };

    return {
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      categoryTree,
      getCatLink,
      handleAccountClick,
      isAuthenticated,
      isSearchOpen,
      productSearchResults,
      setTermForUrl,
      toggleCartSidebar,
      addBasePath,
      headerThemeConfig,
      handleSearchResultsClose,
    };
  },
});
