





























































































import { SfBottomNavigation, SfBadge } from '@storefront-ui/vue';
import {
  computed, defineComponent, useRouter, useContext, watch,
} from '@nuxtjs/composition-api';
import { useUiState } from '~/composables/useUiState';
import { useUser } from '~/modules/customer/composables/useUser';
import SvgImage from '~/components/General/SvgImage.vue';
import { useCategoryStore } from '~/modules/catalog/category/stores/category';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useCurrentStore } from '~/stores/currentStore';
import { storeToRefs } from 'pinia';

const MobileCategorySidebar = () => import('~/modules/catalog/category/components/sidebar/MobileCategorySidebar/MobileCategorySidebar.vue');

export default defineComponent({
  name: 'BottomNavigation',
  components: {
    SfBottomNavigation,
    SfBadge,
    MobileCategorySidebar,
    SvgImage,
  },
  setup() {
    const { loadTotalQty: loadCartTotalQty, cart } = useCart();
    const {
      openCartSidebar,
      isCartSidebarOpen,
      toggleCartSidebar,

      toggleWishlistSidebar,
      toggleLoginModal,
      toggleMobileMenu,
      isMobileMenuOpen,
    } = useUiState();
    const { isAuthenticated } = useUser();
    const router = useRouter();
    const { app } = useContext();

    const currentStore = useCurrentStore();
    const { store } = storeToRefs(currentStore);

    const closeOpenedModals = () => {
      if (isMobileMenuOpen.value) {
        toggleMobileMenu();
      }

      if (isCartSidebarOpen.value) {
        toggleCartSidebar();
      }
    };

    const handleHomeClick = async () => {
      const homePath = app.localeRoute({ name: 'home' });
      closeOpenedModals();

      await router.push(homePath);
    };

    const handleAccountClick = async () => {
      closeOpenedModals();

      if (isAuthenticated.value) {
        await router.push(app.localeRoute({ name: 'customer' }));
      } else {
        toggleLoginModal();
      }
    };

    const loadCategoryMenu = async () => {
      const categories = useCategoryStore();
      if (categories.categories === null) {
        await categories.load();
      }
    };

    const handleCategoryMenuClick = async () => {
      await loadCategoryMenu();

      if (isCartSidebarOpen.value) {
        toggleCartSidebar();
      }

      toggleMobileMenu();
    };

    const handleCartClick = () => {
      toggleCartSidebar();
    };

    watch(() => store.value, () => {
      useCategoryStore().load();
    });

    return {
      cartTotalItems: computed(() => cart.value?.total_quantity ?? 0),
      isAuthenticated,
      isCartSidebarOpen,
      isMobileMenuOpen,
      toggleWishlistSidebar,
      openCartSidebar,
      toggleMobileMenu,

      handleHomeClick,
      handleCategoryMenuClick,
      handleAccountClick,
      handleCartClick,
    };
  },
});
