import { defineStore } from 'pinia';
import type { HeaderStateInterface } from './useHeaderState';
import type { HeaderVariantType } from '../types';

export const useHeaderState = defineStore('headerStore', {
  state: (): HeaderStateInterface => ({
    variant: 'dark',
    logoSvgVariant: 'black',
    cityLocatorVariant: 'normal',
    floating: false,
  }),
  actions: {
    setVariant(variant: HeaderVariantType) {
      this.variant = variant;
      this.logoSvgVariant = variant === 'dark' ? 'black' : 'white';
      this.cityLocatorVariant = variant === 'dark' ? 'normal' : 'translucent';
    },
    setFloating(float: boolean) {
      this.floating = float;
    },
  },
});
