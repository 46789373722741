import { Context } from '@nuxt/types';

import { ruRule } from '~/lang/pluralization/ru';

export default (context: Context) => {
  // eslint-disable-next-line no-param-reassign
  context.app.i18n.pluralizationRules = {
    default: ruRule,
  };
};
