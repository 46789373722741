import {
  computed,
  readonly,
  ref,
  useContext,
} from '@nuxtjs/composition-api';
import type { Ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { useConfigStore } from '~/stores/config';
import { useCurrentStore } from '~/stores/currentStore';
import type { StoreConfig } from '~/modules/GraphQL/types';
import {
  UseStoreInterface,
  UseStoreErrors,
  UseStoreLocation,
  UseStoreLocationData,
} from '~/composables/useStore/useStore';
import getStoreByLocationQuery from '~/composables/useStore/getStoreByLocation.gql';
import useApi from '../useApi';

/**
 * Allows loading and changing currently active store.
 *
 * See the {@link UseStoreInterface} for a list of methods and values available in this composable.
 */
export function useStore(): UseStoreInterface {
  const { query } = useApi();

  const loading: Ref<boolean> = ref(false);
  const error: Ref<UseStoreErrors> = ref({
    load: null,
    change: null,
  });
  const configStore = useConfigStore();
  const currentStore = useCurrentStore();
  const { app, $config } = useContext();

  const load = async (customQuery = { availableStores: 'availableStores' }, customHeaders = {}): Promise<void> => {
    Logger.debug('useStoreFactory.load');
    error.value.load = null;

    try {
      loading.value = true;
      const { data } = await app.$vsf.$magento.api.availableStores(customQuery, customHeaders);

      configStore.$patch((state) => {
        state.stores = data?.availableStores ?? [];
      });
    } catch (err) {
      error.value.load = err;
    } finally {
      loading.value = false;
    }
  };

  const loadByLocation = async (location: UseStoreLocation): Promise<UseStoreLocationData> => {
    Logger.debug('useStoreFactory.loadByLocation');
    error.value.load = null;

    try {
      loading.value = true;
      // const { data } = await query<{ getStoreByLocation: StoreConfig }>(getStoreByLocationQuery, location);
      // return data?.getStoreByLocation;

      const configState = app.$vsf.$magento.config.state;
      return {
        store_code: configState.getStore(),
        default_display_currency_code: configState.getCurrency(),
      };
    } catch (err) {
      error.value.load = err;
    } finally {
      loading.value = false;
    }

    return null;
  };

  const change = (store: StoreConfig) => {
    Logger.debug('useStoreFactory.change', store);

    error.value.change = null;

    const configState = app.$vsf.$magento.config.state;

    if (configState.getStore() === store.store_code) {
      return;
    }

    try {
      loading.value = true;
      configState.setStore(store.store_code);
      configState.setCurrency(store.default_display_currency_code);
      configState.setLocale(store.locale || store.store_code);

      if ($config.changeStoreWithPageReload) {
        const newStoreUrl = app.switchLocalePath(store.store_code);
        window.location.replace(newStoreUrl);
      } else {
        currentStore.$patch((state) => {
          state.store = store.store_code;
        });
      }
    } catch (err) {
      error.value.change = err;
    }

    loading.value = false;
  };

  const changeByLocation = async (location: UseStoreLocation): Promise<void> => {
    Logger.debug('useStoreFactory.changeByLocation', location);

    try {
      const data = await loadByLocation({
        regionFiasId: location.regionFiasId,
        cityFiasId: location.cityFiasId,
      });

      change({
        store_code: data.store_code,
        default_display_currency_code: data.default_display_currency_code,
        locale: 'default',
      });
    } catch (err) {
      Logger.debug('[ERROR] useStoreFactory.changeByLocation', err);
    }
  };

  return {
    stores: computed(() => configStore.stores),
    load,
    loadByLocation,
    change,
    changeByLocation,
    loading: readonly(loading),
    error: readonly(error),
  };
}

export default useStore;
export * from './useStore';
