import { Middleware } from '@nuxt/types';
import { useHeaderState } from '~/components/Header';

const middleware : Middleware = (context) => {
  const { setFloating, setVariant } = useHeaderState(context.$pinia);

  const homepage = context.localeRoute({ name: 'home' });

  if (context.route.name === homepage.name) {
    setFloating(true);
    setVariant('light');
  } else {
    setFloating(false);
    setVariant('dark');
  }
};
export default middleware;
