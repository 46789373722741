import { Context, Plugin } from '@nuxt/types';
import axios from 'axios';

function createApi() {
  return axios.create({});
}

const apiPlugin: Plugin = (context: Context, inject) => {
  const api = createApi();
  inject('api', api);
};

export default apiPlugin;
