import { onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';
import { once } from 'lodash-es';
import type { UseCurrentBreakpointInterface, BreakpointsStop } from './types';
import { BREAKPOINTS } from './constants';

const getCurrentBreakpoint = (width: number): BreakpointsStop => {
  let current: BreakpointsStop = 'desktop';

  // eslint-disable-next-line no-restricted-syntax
  for (const stop of Object.entries(BREAKPOINTS)) {
    const [stopKey, stopValue] = stop;
    if (width < stopValue) {
      current = stopKey as BreakpointsStop;
      break;
    }

    current = stopKey as BreakpointsStop;
  }

  return current;
};

const useCurrentBreakpoint = (): UseCurrentBreakpointInterface => {
  const currentSize = ref({
    width: 1440,
    height: 700,
  });
  const breakpoint = ref('desktop');

  const listenerFn = () => {
    currentSize.value = {
      width: window.innerWidth,
      height: window.innerHeight,
    };

    breakpoint.value = getCurrentBreakpoint(window.innerWidth);
  };

  onMounted(() => {
    window.addEventListener('resize', listenerFn);
    once(listenerFn)();
  });

  onUnmounted(() => {
    window.removeEventListener('resize', listenerFn);
  });

  return {
    currentSize,
    breakpoint,
  };
};

export * from './types';
export { useCurrentBreakpoint };
