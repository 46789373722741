
import { defineComponent, onMounted } from '@nuxtjs/composition-api';
import { useAcceptCookies } from '~/composables';

export default defineComponent({
  name: 'RsgAcceptCookies',

  setup() {
    const { check } = useAcceptCookies();
    onMounted(() => check());
  },

  render(createElement) {
    return createElement();
  },
});
