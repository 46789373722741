import {
  discountCardNumberFragment,
  discountCardBalanceFragment,
} from './loyaltyCardFragments.gql';

export default `
  query getDiscountCard(
    $forceBalanceSync: Boolean
  ) {
    getDiscountCard(
      force_balance_sync: $forceBalanceSync
    ) {
      ...DiscountCardNumberFields
      ...DiscountCardBalanceFields
    }
  }

  ${discountCardNumberFragment}
  ${discountCardBalanceFragment}
`;
