

























import { SfImage } from '@storefront-ui/vue';
import { computed, defineComponent } from '@nuxtjs/composition-api';
import { useConfig } from '~/composables';
import SvgImage from '~/components/General/SvgImage.vue';
import { PropType } from 'vue';
import type { LogoColorTypes } from '../types';

const LOGO_SVG_ICONS: Record<LogoColorTypes, string> = {
  black: 'logo',
  white: 'logo_white',
};

export default defineComponent({
  name: 'HeaderLogo',
  components: { SvgImage, SfImage },
  props: {
    logoColor: {
      type: String as PropType<LogoColorTypes>,
      default: 'black',
    },
  },
  setup(props) {
    const { config } = useConfig();

    const logoSrc = computed(() => {
      const baseMediaUrl = config.value.base_media_url;
      const logo = config.value.header_logo_src;

      return baseMediaUrl && logo ? `${baseMediaUrl}logo/${logo}` : '';
    });

    const logoWidth = computed(
      () => config.value.logo_width || '35',
    );

    const logoHeight = computed(
      () => config.value.logo_height || '34',
    );

    const logoAlt = computed(
      () => config.value.logo_alt || '',
    );

    const logoSvgIcon = computed(() => LOGO_SVG_ICONS[props.logoColor]);

    return {
      logoAlt,
      logoHeight,
      logoSrc,
      logoWidth,
      logoSvgIcon,
    };
  },
});
