










import { defineComponent } from '@nuxtjs/composition-api';
import IconSprite from '~/components/General/IconSprite.vue';
import TrackGtmRoute from '~/components/gtm/track-gtm-route.vue';
import { useScreenHeight } from '~/composables';

export default defineComponent({
  name: 'EmptyLayout',

  components: {
    TrackGtmRoute,
    IconSprite,
  },

  setup() {
    useScreenHeight();
  },
});
