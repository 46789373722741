import { Logger } from '~/helpers/logger';
import { Cart, UpdateCartItemsInput } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';

export const removeAllItemsCommand = {
  execute: async (context: VsfContext, {
    currentCart,
    products,
    customQuery = { updateCartItems: 'rsg-update-cart-items' },
    customHeaders = {},
  }) => {
    Logger.debug('[Magento]: Remove all products from cart', {
      products,
      currentCart,
    });

    const updateCartParams: UpdateCartItemsInput = {
      cart_id: currentCart.id,
      cart_items: products.map((product) => ({
        cart_item_uid: product.uid,
        quantity: 0,
      }))
    };

    const { data } = await context.$magento.api.updateCartItems(updateCartParams, customQuery, customHeaders);

    Logger.debug('[Result]:', { data });

    return data
      .updateCartItems
      .cart as unknown as Cart;
  },
};
