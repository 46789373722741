import { ConfigurableCartItem, ConfigurableProduct } from '~/modules/GraphQL/types';
import { getPrice } from '~/modules/catalog/product/getters/productGetters';
import { CartErrorType } from '~/modules/checkout/composables/useCart/types';

export interface BuildGTMProductsOptions {
  quantity?: number
  category?: string
  variant?: string | null,
  debugKey?: string
}

export function buildGTMCartProduct(product: ConfigurableCartItem, options?: BuildGTMProductsOptions) {
  return {
    id: product.product?.sku?.replace(/_/g, '-') || null,
    name: product.product.name,
    price: getPrice(product.product).final,
    category: options?.category ?? product.product.categories[0].url_path,
    quantity: options?.quantity ?? (product.errors?.some((error) => error.code === CartErrorType.ITEM_QTY) ? 0 : product.quantity),
    variant: options?.variant ?? product?.configured_variant?.sku ?? null,
  };
}

export function buildGTMCartProducts(products: ConfigurableCartItem[], options?: BuildGTMProductsOptions) {
  return products.map((product) => buildGTMCartProduct(product, options));
}

export function buildGTMProduct(product: ConfigurableProduct, options?: BuildGTMProductsOptions) {
  return {
    id: product?.sku?.replace(/_/g, '-') || null,
    name: product.name,
    price: getPrice(product).final,
    category: options?.category ?? null,
    variant: options?.variant ?? product?.configurable_product_options_selection?.variant?.sku ?? null,
    quantity: options?.quantity ?? 1,
  };
}

export function buildGTMProducts(products: ConfigurableProduct[], options?: BuildGTMProductsOptions) {
  return products.map((product) => buildGTMProduct(product, options));
}
