import type {
  DadataResponseApiType,
  DadataSuggestionsResponseApiType,
  DadataCitySuggestionResponseType,
  DadataCitySuggestionType,
  DadataCityLocationType,
  DadataLocationResponseApiType,
} from '../types';
import { buildDadataSuggestCityLocationData } from './common';

function buildDadataSuggestCityLocationSuggestions(
  rawSuggestions: DadataResponseApiType[] | DadataResponseApiType,
): DadataCitySuggestionResponseType[] | DadataCitySuggestionResponseType {
  if (Array.isArray(rawSuggestions)) {
    return rawSuggestions.map(({
      value,
      data,
    }) => ({
      value,
      data: buildDadataSuggestCityLocationData(data),
    }));
  }

  const { value, data } = rawSuggestions;
  return {
    value,
    data: buildDadataSuggestCityLocationData(data),
  };
}

export function buildDadataSuggestCityLocationResponse(response: DadataSuggestionsResponseApiType): DadataCitySuggestionType {
  const { suggestions } = response;

  return {
    suggestions: buildDadataSuggestCityLocationSuggestions(suggestions) as DadataCitySuggestionResponseType[],
  };
}

export function buildDadataLocateCityLocationResponse(response: DadataLocationResponseApiType): DadataCityLocationType {
  const { location } = response;

  return {
    location: buildDadataSuggestCityLocationSuggestions(location) as DadataCitySuggestionResponseType,
  };
}
