const middleware = {}

middleware['checkout'] = require('../middleware/checkout.ts')
middleware['checkout'] = middleware['checkout'].default || middleware['checkout']

middleware['header-float'] = require('../middleware/header-float.ts')
middleware['header-float'] = middleware['header-float'].default || middleware['header-float']

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['url-resolver'] = require('../middleware/url-resolver.ts')
middleware['url-resolver'] = middleware['url-resolver'].default || middleware['url-resolver']

middleware['user-ip'] = require('../middleware/user-ip.ts')
middleware['user-ip'] = middleware['user-ip'].default || middleware['user-ip']

export default middleware
