import { useContext } from '@nuxtjs/composition-api';
import { useUiNotification } from '~/composables';
import type { UseAcceptCookies } from './useAcceptCookies';

const COOKIES_ACCEPTANCE_KEY = 'rsg-user-accept-cookies';

export function useAcceptCookies(): UseAcceptCookies {
  const { $cookies } = useContext();
  const monthPeriodInSeconds = 31 * 24 * 60 * 60;
  const accepted = $cookies.get(COOKIES_ACCEPTANCE_KEY);

  const acceptManually = () => {
    $cookies.set(COOKIES_ACCEPTANCE_KEY, true, {
      maxAge: monthPeriodInSeconds,
    });
  };

  const { send: sendNotification } = useUiNotification();

  const check = () => {
    if (!accepted) {
      sendNotification({
        id: Symbol('accept_cookies'),
        message: '',
        type: 'cookies',
        icon: null,
        persist: true,
        dismiss: () => acceptManually(),
      });
    }
  };

  return {
    check,
  };
}

export default useAcceptCookies;
