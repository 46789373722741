














import {
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';

export const CONTENT_WRAPPER_SIZE = {
  XL: 'xl',
  M: 'm',
};

export default defineComponent({
  name: 'ContentWrapper',
  props: {
    size: {
      type: String,
      default: CONTENT_WRAPPER_SIZE.M,
      validator: (value: string) => Object.values(CONTENT_WRAPPER_SIZE).includes(value),
    },
    noPadding: {
      type: Boolean,
      default: false,
    },
    noLeftPadding: {
      type: Boolean,
      default: false,
    },
    noRightPadding: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const noLeftPaddingInternal = computed(() => props.noLeftPadding || props.noPadding);
    const noRightPaddingInternal = computed(() => props.noRightPadding || props.noPadding);

    return {
      noLeftPaddingInternal,
      noRightPaddingInternal,
    };
  },
});
