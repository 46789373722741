export const discountCardNumberFragment = `
  fragment DiscountCardNumberFields on DiscountCard {
    number
    number_formatted
  }
`;

export const discountCardBalanceFragment = `
  fragment DiscountCardBalanceFields on DiscountCard {
    reward_points_balance
  }
`;
