import { Context, Plugin } from '@nuxt/types';
import { createPersistedStatePlugin } from '~/stores/plugins/persisted-state-plugin';

const persistedStatePlugin: Plugin = ({ $pinia }: Context) => {
  if (process.client) {
    $pinia.use(createPersistedStatePlugin);
  }
};

export default persistedStatePlugin;
