import {
  ref,
  watch,
  watchSyncEffect,
  watchPostEffect,
  useRoute,
} from '@nuxtjs/composition-api';
import { defineStore } from 'pinia';

export const useLocateCityDrawerState = defineStore('locate-city-drawer', () => {
  const acceptDrawerVisible = ref(false);
  const searchDrawerVisible = ref(false);

  const route = useRoute();

  const setSearchDrawerVisibility = (e: boolean) => {
    searchDrawerVisible.value = e;
  };

  const setAcceptDrawerVisibility = (e: boolean) => {
    acceptDrawerVisible.value = e;
  };

  const setScrollLockingStyles = () => {
    document.documentElement.style.setProperty('overflow', 'hidden');
    document.documentElement.style.setProperty('height', '100%');

    document.body.style.setProperty('overflow', 'hidden');
    document.body.style.setProperty('position', 'relative');
    document.body.style.setProperty('height', '100%');
  };

  const removeScrollLockingStyles = () => {
    document.documentElement.style.removeProperty('overflow');
    document.documentElement.style.removeProperty('height');

    document.body.style.removeProperty('overflow');
    document.body.style.removeProperty('position');
    document.body.style.removeProperty('height');
  };

  watchSyncEffect(() => {
    if (process.server) {
      return;
    }

    if (searchDrawerVisible.value) {
      setScrollLockingStyles();
    } else {
      removeScrollLockingStyles();
    }
  });

  watchPostEffect(() => {
    if (process.server) {
      return;
    }

    if (acceptDrawerVisible.value) {
      setScrollLockingStyles();
    } else {
      removeScrollLockingStyles();
    }
  });

  watch(
    () => route.value.fullPath,
    () => {
      setSearchDrawerVisibility(false);
    },
  );

  return {
    acceptDrawerVisible,
    searchDrawerVisible,
    setSearchDrawerVisibility,
    setAcceptDrawerVisibility,
  };
});
