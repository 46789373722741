













import { defineComponent } from '@nuxtjs/composition-api';
import { SfTopBar } from '@storefront-ui/vue';
import DadataLocateCity from '~/components/dadata/locate-city';
import HeaderSupport from '~/components/Header/Support/HeaderSupport.vue';

export default defineComponent({
  name: 'TopBar',
  components: {
    SfTopBar,
    DadataLocateCity,
    HeaderSupport,
  },
});

