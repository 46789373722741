import _Vue from 'vue';
import { Context } from '@nuxt/types';
import type { RawLocation, Route } from 'vue-router';

export default (context: Context) => {
  const {
    localePath: originalLocalePath,
    localeRoute: originalLocaleRoute,
  } = context.app;

  /**
   * Decode URI component in the path as there is nuxt/i18n plugin
   * with no_prefix mode affects on resolve routing
   * that it escapes slashes in route
   *
   * origin route { path: "a/b"}
   * => first resolving: { params: { slug: "a/b" }}
   * => second resoling: { params: { slug: "a%2Fb" }}
   */
  const unescapePath = (path: string): string => decodeURIComponent(path);

  const localePath = (route: RawLocation, locale?: string): string => {
    const path: string = originalLocalePath(route, locale);
    return unescapePath(path).toLowerCase();
  };

  const localeRoute = (route: RawLocation, locale?: string): Route | undefined => {
    const resultRoute: Route | undefined = originalLocaleRoute(route, locale);

    if (resultRoute === undefined) {
      return resultRoute;
    }

    return {
      ...resultRoute,
      path: unescapePath(resultRoute.path).toLowerCase(),
      fullPath: unescapePath(resultRoute.fullPath).toLowerCase(),
    };
  };

  const plugin = {
    install(Vue: typeof _Vue) {
      Vue.mixin({
        methods: {
          localePath,
          localeRoute,
        },
      });
    },
  };

  _Vue.use(plugin);
  const { app, store } = context;

  // eslint-disable-next-line no-param-reassign
  context.localePath = localePath;
  // eslint-disable-next-line no-param-reassign
  context.localeRoute = localeRoute;
  app.localePath = localePath;
  app.localeRoute = localeRoute;

  if (store) {
    store.localePath = app.localePath;
    store.localeRoute = app.localeRoute;
  }
};
