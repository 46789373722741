import { computed, reactive } from '@nuxtjs/composition-api';
import type { TrackGtmRouteStore } from './track-gtm-route.types';

const store = reactive<TrackGtmRouteStore>({
  initialPage: false,
});

export const useTrackGtmRoute = () => {
  const setInitialPage = (e: boolean) => {
    store.initialPage = e;
  };

  return {
    initialPage: computed(() => store.initialPage),
    setInitialPage,
  };
};
