import { DadataAddressResponseApiType, DadataSuggestionsAddressType, DadataCitySuggestionAddressType } from '../types';

export function buildDadataSuggestAddressSuggestionsData({
  region_with_type: regionWithType,

  area_with_type: areaWithType,

  postal_code: postcode,

  city,
  city_with_type: cityWithType,
  city_kladr_id: cityKladrId,

  settlement,
  settlement_with_type: settlementWithType,
  settlement_kladr_id: settlementKladrId,

  street,
  street_type: streetType,
  street_with_type: streetWithType,

  house,
  house_type: houseType,
  house_type_full: houseTypeFull,

  block,
  block_type: blockType,
  block_type_full: blockTypeFull,

  geo_lat: latitude,
  geo_lon: longitude,
}: DadataAddressResponseApiType): DadataSuggestionsAddressType {
  return {
    regionWithType,

    areaWithType,

    postcode,

    city,
    cityWithType,
    cityKladrId,

    settlement,
    settlementWithType,
    settlementKladrId,

    street,
    streetType,
    streetWithType,

    house,
    houseType,
    houseTypeFull,

    block,
    blockType,
    blockTypeFull,

    latitude: Number(latitude),
    longitude: Number(longitude),
  };
}

export function buildDadataSuggestCityLocationData({
  fias_id: fiasId,

  postal_code: postalCode,

  country_iso_code: countryISOCode,

  region_with_type: regionWithType,
  region_fias_id: regionFiasId,
  region_kladr_id: regionKladrId,

  area_with_type: areaWithType,

  city,
  city_fias_id: cityFiasId,
  city_kladr_id: cityKladrId,

  settlement,
  settlement_with_type: settlementWithType,
  settlement_fias_id: settlementFiasId,
  settlement_kladr_id: settlementKladrId,

  geo_lat: latitude,
  geo_lon: longitude,

  value,
}: DadataAddressResponseApiType): DadataCitySuggestionAddressType {
  return {
    fiasId,

    postalCode,

    countryISOCode,

    regionWithType,
    regionFiasId,
    regionKladrId,

    areaWithType,

    city,
    cityFiasId,
    cityKladrId,

    settlement,
    settlementWithType,
    settlementFiasId,
    settlementKladrId,

    latitude: Number(latitude),
    longitude: Number(longitude),

    value,
  };
}
