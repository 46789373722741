import type {
  GTMEventPurchase,
  GTMEventBeginCheckout,
  GTMEventAddToCart,
  GTMEventPageView,
  GTMEventViewItemList,
  GTMEventViewItem,
  GTMEventLogin,
  GTMEventSignUp,
  GTMEventViewCart,
  GTMEventViewPromotion,
  GTMEventSelectPromotion,
  GTMEventRemoveFromCart,
  GTMEventDeliveryCheckout,
  GTMEventPaymentCheckout,
  GTMEventFinishCheckout,
} from '../useGTM';

export const EVENTS = {
  purchase: ({
    deliveryType,
    paymentType,
    isPaid,

    currencyCode,

    actionId,
    revenue,
    shipping,
    coupon,

    products,
  }: GTMEventPurchase) => ({
    event: 'tm_e_ee',
    tm_event: 'purchase',
    tm_event_interaction: true,

    tm_data: {
      delivery_type: deliveryType,
      payment_type: paymentType,
      is_paid: isPaid,
    },

    ecommerce: {
      currencyCode,

      purchase: {
        actionField: {
          id: actionId,
          revenue,
          shipping,
          coupon: coupon || null,
        },

        products,
      },
    },
  }),

  begin_checkout: ({
    currencyCode,
    cartId,
    products,
  }: GTMEventBeginCheckout) => ({
    event: 'tm_e_ee',
    tm_event: 'begin_checkout',
    tm_event_interaction: true,

    tm_data: {
      cart_id: cartId,
    },

    ecommerce: {
      currencyCode,

      checkout: {
        actionField: {
          option: null,
          list: null,
          step: -1,
        },

        products,
      },
    },
  }),

  delivery_checkout: ({
    currencyCode,
    cartId,
    products,
    deliveryType,
  }: GTMEventDeliveryCheckout) => ({
    event: 'tm_e_ee',
    tm_event: 'delivery_checkout',
    tm_event_interaction: true,

    tm_data: {
      cart_id: cartId,
    },

    ecommerce: {
      currencyCode,

      checkout: {
        actionField: {
          option: deliveryType,
        },

        products,
      },
    },
  }),

  payment_checkout: ({
    currencyCode,
    cartId,
    products,
    paymentType,
  }: GTMEventPaymentCheckout) => ({
    event: 'tm_e_ee',
    tm_event: 'payment_checkout',
    tm_event_interaction: true,

    tm_data: {
      cart_id: cartId,
    },

    ecommerce: {
      currencyCode,

      checkout: {
        actionField: {
          option: paymentType,
        },

        products,
      },
    },
  }),

  finish_checkout: ({
    currencyCode,
    cartId,
    products,

    deliveryType,
    paymentType,
  }: GTMEventFinishCheckout) => ({
    event: 'tm_e_ee',
    tm_event: 'finish_checkout',
    tm_event_interaction: true,

    tm_data: {
      cart_id: cartId,
      delivery_type: deliveryType,
      payment_type: paymentType,
    },

    ecommerce: {
      currencyCode,

      checkout: {
        products,
      },
    },
  }),

  add_to_cart: ({
    currencyCode,
    cartId,
    products,
  }: GTMEventAddToCart) => ({
    event: 'tm_e_ee',
    tm_event: 'add_to_cart',
    tm_event_interaction: true,

    tm_data: {
      cart_id: cartId,
    },

    ecommerce: {
      currencyCode,

      add: {
        products,
      },
    },
  }),

  remove_from_cart: ({
    currencyCode,
    cartId,
    products,
  }: GTMEventRemoveFromCart) => ({
    event: 'tm_e_ee',
    tm_event: 'remove_from_cart',
    tm_event_interaction: true,

    tm_data: {
      cart_id: cartId,
    },

    ecommerce: {
      currencyCode,

      add: {
        products,
      },
    },
  }),

  page_view: ({
    pagePath,
  }: GTMEventPageView) => ({
    event: 'tm_pv',
    tm_event: 'page_view',
    tm_event_interaction: false,

    tm_data: {
      page_path: pagePath,
    },
  }),

  view_item: ({
    currencyCode,

    itemCategory,
    products,
  }: GTMEventViewItem) => ({
    event: 'tm_e_ee',
    tm_event: 'view_item',
    tm_event_interaction: false,

    tm_data: {
      item_category: itemCategory,
    },

    ecommerce: {
      currencyCode,

      detail: products,
    },
  }),

  view_item_list: ({
    currencyCode,

    catalogId,
    products,
  }: GTMEventViewItemList) => ({
    event: 'tm_e_ee',
    tm_event: 'view_item_list',
    tm_event_interaction: false,

    tm_data: {
      catalog_id: catalogId ?? null,
    },

    ecommerce: {
      currencyCode,

      impressions: products,
    },
  }),

  login: ({
    method,
  }: GTMEventLogin) => ({
    event: 'tm_e_ui',
    tm_event: 'login',
    tm_event_interaction: true,

    tm_data: {
      method,
    },
  }),

  sign_up: ({
    method,
  }: GTMEventSignUp) => ({
    event: 'tm_e_ui',
    tm_event: 'sign_up',
    tm_event_interaction: true,

    tm_data: {
      method,
    },
  }),

  view_cart: ({
    currencyCode,
    cartId,
    products,
  }: GTMEventViewCart) => ({
    event: 'tm_e_ee',
    tm_event: 'view_cart',
    tm_event_interaction: true,

    tm_data: {
      cart_id: cartId,
    },

    ecommerce: {
      currencyCode,

      cart: {
        products,
      },
    },
  }),

  view_promotion: ({
    clickable,

    promotionId,
    promotionName,
    promotionCreative,
    promotionPosition,
  }: GTMEventViewPromotion) => ({
    event: 'tm_e_ee',
    tm_event: 'view_promotion',
    tm_event_interaction: false,

    tm_data: {
      clickable,
    },

    ecommerce: {
      promoView: {
        promotions: {
          id: promotionId || null,
          name: promotionName || null,
          creative: promotionCreative || null,
          position: promotionPosition ?? null,
        },
      },
    },
  }),

  select_promotion: ({
    bannerLink,

    promotionId,
    promotionName,
    promotionCreative,
    promotionPosition,
  }: GTMEventSelectPromotion) => ({
    event: 'tm_e_ee',
    tm_event: 'select_promotion',
    tm_event_interaction: false,

    tm_data: {
      banner_link: bannerLink,
    },

    ecommerce: {
      promoView: {
        promotions: {
          id: promotionId || null,
          name: promotionName || null,
          creative: promotionCreative || null,
          position: promotionPosition ?? null,
        },
      },
    },
  }),
};
