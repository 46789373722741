import { defineStore } from 'pinia';
import type { GeolocationState } from './types';

const useGeolocationStore = defineStore('geolocationStore', {
  state: (): GeolocationState => ({
    location: null,
    ip: null,

    toConfirmLocation: false,
  }),
  actions: {
    setIp(ip: string) {
      if (!this.ip && ip) this.ip = ip;
    },
  },
  persist: true,
});

export { useGeolocationStore };
