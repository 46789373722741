
























import AcceptCookies from '~/components/AcceptCookies.vue';
import LazyHydrate from 'vue-lazy-hydration';
import { useRoute, defineComponent } from '@nuxtjs/composition-api';
import { useUiState, useScreenHeight } from '~/composables';
import { AppHeader } from '~/components/Header';
import BottomNavigation from '~/components/BottomNavigation.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import TrackGtmRoute from '~/components/gtm/track-gtm-route.vue';

export default defineComponent({
  name: 'AccountLayout',

  components: {
    TrackGtmRoute,
    AcceptCookies,
    LazyHydrate,
    AppHeader,
    BottomNavigation,
    IconSprite,
    CartSidebar: () => import(/* webpackPrefetch: true */ '~/modules/checkout/components/CartSidebar.vue'),
    RsgLoginByCodeModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/login-by-code-modal'),
    RsgCustomerBarcodeModal: () => import(/* webpackPrefetch: true */ '~/modules/customer/components/barcode-modal'),
    Notification: () => import(/* webpackPrefetch: true */ '~/components/Notification.vue'),
  },

  setup() {
    useScreenHeight();

    const route = useRoute();
    const {
      isBarcodeModalOpen,
      isCartSidebarOpen,
      isLoginModalOpen,
      toggleBarcodeModal,
      toggleLoginModal,
    } = useUiState();

    return {
      isBarcodeModalOpen,
      isCartSidebarOpen,
      isLoginModalOpen,
      toggleBarcodeModal,
      toggleLoginModal,
      route,
    };
  },
});
